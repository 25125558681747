// APP
const AppSelector = {
    PHONE: '[data-app-phone]',
    APP_NAME: '[data-app-name]',
};

// UPI
const UPISelector = {
    COLLECT_VPA: '[data-upi-id]',
    INTENT_PROVIDER: '[data-upi-provider]',
};

// CARD
const CardSelector = {
    CARD_NUMBER: '[data-card-number]',
    CARD_CVV: '[data-card-cvv]',
    CARD_EXPIRY_MONTH: '[data-card-expiry-mm]',
    CARD_EXPIRY_YEAR: '[data-card-expiry-yy]',
    CARD_HOLDER: '[data-card-holder]',
};

// NET BANKING
const NetBankingSelector = {
    BANK_CODE: '[data-netbanking-code]',
    BANK_IFSC_CODE: '[data-netbanking-ifsc]',
    BANK_ACCOUNT_NUMBER: '[data-netbanking-account]',
};

// EMI
const EMISelector = {
    CARD_NUMBER: '[data-emi-number]',
    CARD_CVV: '[data-emi-cvv]',
    CARD_EXPIRY_MONTH: '[data-emi-expiry-mm]',
    CARD_EXPIRY_YEAR: '[data-emi-expiry-yy]',
    BANK_NAME: '[data-emi-bank]',
    EMI_TENURE: '[data-emi-tenure]',
    CARD_HOLDER: '[data-emi-cardholder]',
};

// Paylater
const PaylaterSelector  = {
    PHONE: '[data-paylater-phone]',
    PROVIDER: '[data-provider]',
}

// Cardless EMI
const CardlessSelector = {
    PHONE: '[data-cardless-phone]',
    PROVIDER: '[data-cardless-provider]',
}

export { AppSelector, CardSelector, EMISelector, NetBankingSelector, UPISelector, PaylaterSelector, CardlessSelector };
