import { Order } from '../Order';
import { CARD_LINK } from '../../shared/constants/payment-channels';
import { CardlessSelector } from '../../shared/constants/selectors';
import { checkElementsValidity, checkInputValidity, checkPaymentRequestValidity} from './CardlessEMIValidation';
import analytics from "../../analytics";

export default class cardlessEMI {
    constructor(cardlessElement, onChange) {
        this._cardlessElement = cardlessElement;
        this._onChange = onChange;
        this._state = {
            provider: '',
            phoneNumber: '',
        };
        this._setSelectors();
        checkElementsValidity(cardlessElement);
        this._syncState();
        this._registerListeners();
        analytics.track("open_cardless");
    }

    _syncState() {
        this._state.provider = this._providerElement.value;
        this._state.phoneNumber = this._phoneNumberElement.value;
    }

    _registerListeners = () => {
        this._phoneNumberElement.addEventListener('keyup', this._handlePhoneNumberChange);
        this._phoneNumberElement.addEventListener('change', this._handlePhoneNumberChange);
        this._providerElement.addEventListener('change', this._handleProviderNameChange);
    };

    _handleProviderNameChange = () => {
        this._state.provider = this._providerElement.value;
        if (this._onChange) {
            this._onChange(checkInputValidity(this._state));
        }
    };

    _handlePhoneNumberChange = () => {
        this._state.phoneNumber = this._phoneNumberElement.value;
        if (this._onChange) {
            this._onChange(checkInputValidity(this._state));
        }
    };

    _setSelectors = () => {
        this._phoneNumberElement = this._cardlessElement.querySelector(CardlessSelector.PHONE);
        this._providerElement = this._cardlessElement.querySelector(CardlessSelector.PROVIDER);
    };

    isValid = () => {
        const {
            provider,
        } = this._state;
        if(!provider ) {
            return false;
        }
        return true;
    }

    pay = async (paymentSessionID, pluginName, locationParent) => {
        this._syncState();
        checkPaymentRequestValidity(this._state);
        const paymentMethod = {
            cardless_emi: {
                channel: CARD_LINK,
                provider: this._state.provider,
                phone: this._state.phoneNumber,
            },
        };
        const orderPayResponse = await Order.pay(paymentSessionID, paymentMethod, 'cardlessemi', pluginName, locationParent);
        return orderPayResponse.response;
    };

    handlePaymentResponse = (response, abortPaymentCallback) => Order.handlePaymentResponse(response, 'cardlessemi', null, abortPaymentCallback);
}
