import "../../styles/Intent.css";

export default class IntentModal {
    
    renderCrossIcon = () => '<svg class="intent-close-modal" width="14px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g id="Cross" transform="translate(-5.000000, -5.000000)" stroke="#434343" stroke-width="3"><g id="back-arrow"><g id="Cross" transform="translate(6.000000, 6.000000)"><polyline id="Path-6" points="24 -7.07767178e-15 12 12 24 24"></polyline><polyline id="Path-6" transform="translate(6.000000, 12.000000) scale(-1, 1) translate(-6.000000, -12.000000) " points="12 -7.07767178e-15 0 12 12 24"></polyline></g></g></g></g></svg>';

    renderModal(url, appName, iosAppOpened, abortPayment) {
        this.url = url;
        const overlayDiv = document.createElement('div');
        overlayDiv.innerHTML = `
            <div class="intent-modal">
                <div class="intent-modal-container"> 
                    <div id="intent-cross-modal"class="intent-modal-cross">${this.renderCrossIcon()}</div>
                    <div>
                    <a class="intent-modal-app" target="_blank" href=${url} id="ios-app-click">
                        Open ${appName} app
                    </a>
                    </div>
                </div>
            </div>
        `;
        document.body.appendChild(overlayDiv);
        document.getElementById('ios-app-click').addEventListener('click', () => {
            document.body.removeChild(overlayDiv);
            iosAppOpened();
        });
        document.getElementById('intent-cross-modal').addEventListener('click', () => {
            document.body.removeChild(overlayDiv);
            abortPayment();
        })
    }
}