import { PaylaterSelector } from '../../shared/constants/selectors';
import { openIframeWithSource } from '../../shared/iframe-helper';
import { Order } from '../Order';
import { checkElementsValidity, checkInputValidity, checkPaymentRequestValidity } from './PaylaterValidation';
import analytics from '../../analytics';

export default class Paylater {
    constructor(paylaterElement, onChange) {
        this._paylaterElement = paylaterElement;
        this._onChange = onChange;
        this._state = {
            phoneNumber: '',
            provider: '',
        };
        this._setSelector();
        checkElementsValidity(paylaterElement);
        this._syncState();
        this._registerListener();
        analytics.track('open_paylater');
    }

    _syncState = () => {
        this._state.phoneNumber = this._phoneNumberElement.value;
        this._state.provider = this._paylaterElement.value;
    };

    _setSelector = () => {
        this._phoneNumberElement = this._paylaterElement.querySelector(PaylaterSelector.PHONE);
        this._paylaterElement = this._paylaterElement.querySelector(PaylaterSelector.PROVIDER);
    };

    _registerListener = () => {
        this._phoneNumberElement.addEventListener('keyup', this._handlePhoneNumberChange);
        this._phoneNumberElement.addEventListener('change', this._handlePhoneNumberChange);
        this._paylaterElement.addEventListener('change', this._handleProviderNameChange);
    };

    _handlePhoneNumberChange = () => {
        this._state.phoneNumber = this._phoneNumberElement.value;
        if (this._onChange) {
            this._onChange(checkInputValidity(this._state));
        }
    };

    _handleProviderNameChange = () => {
        this._state.provider = this._paylaterElement.value;
        if (this._onChange) {
            this._onChange(checkInputValidity(this._state));
        }
    };

    isValid = () => {
        if (!this._state.provider) {
            return false;
        }
        return true;
    };

    pay = async (paymentSessionID, pluginName, locationParent) => {
        this._syncState();
        checkPaymentRequestValidity(this._state);
        let { provider } = this._state;
        if (provider === 'Kotak Paylater') {
            provider = 'kotak';
        } else if (provider === 'zestmoneypaylater') {
            provider = 'zestmoney';
        } else if (provider === 'Hdfc Paylater') {
            provider = 'flexipay';
        }
        const paymentMethod = {
            paylater: {
                channel: 'link',
                provider,
                phone: this._state.phoneNumber,
            },
        };

        const orderPayResponse = await Order.pay(paymentSessionID, paymentMethod, 'paylater', pluginName, locationParent);
        this._statusToken = orderPayResponse.statusToken;
        this.payData = {
            pluginName,
        };
        return orderPayResponse.response;
    };

    handlePaymentResponse = (response, abortPaymentCallback, paymentData, payHeadless) => {
        paymentData({...this._state, paymentMode: 'paylater'});
        if (response.action === 'link') {
            openIframeWithSource('0', `${REDIRECT_HOST_URL}/status/gpay/${this._statusToken}`); // Poll for status irrespective of action
        }
        response.payData = this.payData;
        return Order.handlePaymentResponse(response, this._state.channel, this._statusToken, abortPaymentCallback, payHeadless);
    };
}
