import { DOMErrors } from '../../shared/constants/error-codes';
import { inputValidationError } from '../../shared/constants/error-types';
import { CardlessSelector } from '../../shared/constants/selectors';
import { CashfreeException } from '../../shared/exception-helper';
import { phoneNumberRegex } from '../../shared/regex';
import { initialiseValidationCallbackData, isReadyToPay } from '../../shared/validation-helper';

const isValidPhoneNumber = phone => phoneNumberRegex.test(phone);

const checkInputValidity = paymentRequest => {
    const validityData = initialiseValidationCallbackData([CardlessSelector.PHONE, CardlessSelector.PROVIDER]);

    if (!isValidPhoneNumber(paymentRequest.phoneNumber)) {
        validityData.errors[0].error = true;
        validityData.errors[0].message = 'invalid phone number';
    }

    if (!paymentRequest.provider) {
        validityData.errors[1].error = true;
        validityData.errors[1].message = 'invalid provider name';
    }

    validityData.isReadyToPay = isReadyToPay(validityData.errors);
    return validityData;
};

const checkElementsValidity = appElementWrapper => {
    if (appElementWrapper.querySelector(CardlessSelector.PHONE) === null) {
        throw new CashfreeException(DOMErrors.CARDLESS_PHONE_NUMBER_ELEMENT_NOT_FOUND);
    }
    if (appElementWrapper.querySelector(CardlessSelector.PROVIDER) === null) {
        throw new CashfreeException(DOMErrors.CARDLESS_PROVIDER_ELEMENT_NOT_FOUND);
    }
};

const checkPaymentRequestValidity = paymentRequest => {
    if (!isValidPhoneNumber(paymentRequest.phoneNumber)) {
        throw new CashfreeException('invalid phone number', inputValidationError, 'cardlessEmi');
    }
    if (!paymentRequest.provider) {
        throw new CashfreeException('invalid provider', inputValidationError, 'cardlessEmi');
    }
};

export { checkElementsValidity, checkInputValidity, checkPaymentRequestValidity };
