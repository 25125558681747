import { DOMErrors } from '../../shared/constants/error-codes';
import { domError, inputValidationError } from '../../shared/constants/error-types';
import { EMISelector } from '../../shared/constants/selectors';
import { CashfreeException } from '../../shared/exception-helper';
import { CardValidation } from '../card/CardValidation';

const _isValidBankNameElement = bankNameElement => bankNameElement != null;

const _isValidEmiTenureElement = emiTenureElement => emiTenureElement != null;

export default class EmiValidation extends CardValidation {
    // https://javascript.info/class-inheritance
    // Arrow functions [Properties] have no super, so using non-arrow function [Methods]
    checkElementsValidity(cardElementsWrapper, checkCardValidity) {
        if(checkCardValidity) {
            super.checkElementsValidity(cardElementsWrapper);
        }
        if (!_isValidBankNameElement(cardElementsWrapper.querySelector(EMISelector.BANK_NAME))) {
            throw new CashfreeException(DOMErrors.Emi.CARD_BANK_NAME_ELEMENT_NOT_FOUND, domError);
        }
        if (!_isValidEmiTenureElement(cardElementsWrapper.querySelector(EMISelector.EMI_TENURE))) {
            throw new CashfreeException(DOMErrors.Emi.CARD_EMI_TENURE_ELEMENT_NOT_FOUND, domError);
        }
    }

    checkInputValidity(paymentRequest) {
        const validityData = super.checkInputValidity(paymentRequest);
        const augmentedErrorsList = [
            ...validityData.errors,
            {
                element: EMISelector.BANK_NAME,
                error: false,
                message: '',
            },
            {
                element: EMISelector.EMI_TENURE,
                error: false,
                message: '',
            },
        ];
        validityData.errors = augmentedErrorsList;
        // check the validity of bank name and emi tenure
        if (!paymentRequest.cardBankName && validityData.errors[5]) {
            validityData.errors[5].error = true;
            validityData.errors[5].message = 'invalid bank name';
            validityData.isReadyToPay = false;
        }
        if (!paymentRequest.emiTenure && validityData.errors[6]) {
            validityData.errors[6].error = true;
            validityData.errors[6].message = 'invalid bank name';
            validityData.isReadyToPay = false;
        }

        return validityData;
    }

    checkPaymentRequestValidity(paymentRequest) {
        super.checkPaymentRequestValidity(paymentRequest);
        if (!paymentRequest.cardBankName) {
            throw new CashfreeException('invalid bank name', inputValidationError);
        }
        if (!paymentRequest.emiTenure) {
            throw new CashfreeException('invalid emi tenure', inputValidationError);
        }
    }
}
