import CardBase from './CardBase';
import { Order } from '../Order';
import { CardSelector } from '../../shared/constants/selectors';
import { CARD_POST, CARD_LINK } from '../../shared/constants/payment-channels';
import analytics from "../../analytics";
import { getCardBrand } from './CardValidation';

export default class Card extends CardBase {
    constructor(cardElement, onChange) {
        super(cardElement, onChange, 'card');
        this._setSelectors();
        this._validator.checkElementsValidity(cardElement);
        this._syncState();
        this._registerListeners();
        analytics.track("open_card");
    }

    _setSelectors = () => {
        this._cardNumberElement = this._cardElement.querySelector(CardSelector.CARD_NUMBER);
        this._cardCvvElement = this._cardElement.querySelector(CardSelector.CARD_CVV);
        this._cardExpiryMonthElement = this._cardElement.querySelector(CardSelector.CARD_EXPIRY_MONTH);
        this._cardExpiryYearElement = this._cardElement.querySelector(CardSelector.CARD_EXPIRY_YEAR);
        this._cardHolderElement = this._cardElement.querySelector(CardSelector.CARD_HOLDER);
    };

    isValid = () => {
        const {
            cardNumber,
            cvv,
            cardExpiryMonth,
            cardExpiryYear,
            cardHolder
        } = this._state;
        if(!cardNumber || !cvv || !cardExpiryMonth || !cardExpiryYear || !cardHolder) {
            return false;
        }
        return true;
    }

    pay = async (paymentSessionID, pluginName, showBankPage, locationParent) => {
        this._validator.checkPaymentRequestValidity(this._state);
        const state = {...this._state};
        const brand = getCardBrand(state.cardNumber);
        const paymentMethod = {
            card: {
                channel: showBankPage ? CARD_LINK : CARD_POST,
                card_number: this._state.cardNumber,
                card_holder_name: this._state.cardHolder,
                card_expiry_mm: this._state.cardExpiryMonth,
                card_expiry_yy: this._state.cardExpiryYear,
                card_cvv: this._state.cvv,
            },
        };

        const orderPayResponse = await Order.pay(paymentSessionID, paymentMethod, 'card', pluginName, locationParent);
        this.bankData = {...state, brand};
        this.payData = {
            pluginName
        };
        return orderPayResponse.response;
    };
}
