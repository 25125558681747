const iframeId = 'cashfree-pay-frame';

/**
 *
 * @param {*} id
 * @param {*} height
 * @description eager loading of iframe is a must since chrome lazy loads iframe by default in lite mode which won't trigger recon calls
 * @link https://web.dev/iframe-lazy-loading/#wait-can't-browsers-just-automatically-lazy-load-offscreen-iframes
 * @returns
 */

const getIframeTemplate = (id, height) => {
    const template = `
    <div 
        id="cashfree-iframe-container" 
        style="position:fixed; overflow:hidden; width:100%; border-radius:5px;"
    >
        <iframe 
            id="${id}" loading="eager"
            style="position: absolute; top:50%; left:50%; transform:translate(-50%, -50%); height:${height}; width: 420px; padding:56px; padding-left:0; padding-right:0; border:transparent;"
        >
        </iframe>
    </div>`;
    return template;
};

const openIframe = height => {
    const iframeTempWrapper = document.createElement('div');
    iframeTempWrapper.innerHTML = getIframeTemplate(iframeId, height || '100%');
    document.body.appendChild(iframeTempWrapper);
    return document.getElementById(iframeId);
};

const openIframeWithSource = (height, src) => {
    openIframe(height);
    document.getElementById(iframeId).src = src;
};

const closeIframe = () => {
    document.getElementById('cashfree-iframe-container').remove(); // remove iframe with container element
};

const listenToIframe = callback => {
    window.addEventListener('message', event => {
        const allowedOrigins = [
            'http://localhost:3190',
            'https://gamma.cashfree.com',
            'https://sandbox.cashfree.com',
            'https://test.cashfree.com',
            'https://payments-test.cashfree.com',
            'https://payments.cashfree.com',
            'https://api.cashfree.com',
            'https://www.cashfree.com',
        ];
        if (allowedOrigins.indexOf(event.origin) > -1) {
            callback(event.data);
        }
    });
};

export { openIframe, openIframeWithSource, closeIframe, listenToIframe, getIframeTemplate };
