import { Order } from '../Order';
import { CARD_LINK } from '../../shared/constants/payment-channels';
import { EMISelector } from '../../shared/constants/selectors';
import CardBase from '../card/CardBase';

export default class EMI extends CardBase {
    constructor(cardElement, onChange) {
        super(cardElement, onChange, 'emi');
        this._state = {
            ...this._state,
            cardBankName: '',
            emiTenure: 0,
        };
        this._setSelectors();
        this._validator.checkElementsValidity(cardElement);
        this._syncState();
        this._registerListeners();
    }

    _syncState() {
        super._syncState();
        this._state.cardBankName = this._cardBankNameElement.value;
        this._state.emiTenure = this._cardEmiTenureElement.value;
    }

    _registerListeners = () => {
        super._registerListeners();
        this._cardBankNameElement.addEventListener('change', this._handleCardBankNameChange);
        this._cardEmiTenureElement.addEventListener('change', this._handleCardEmiTenureChange);
        this._cardBankNameElement.addEventListener('keyup', this._handleCardBankNameChange);
        this._cardEmiTenureElement.addEventListener('keyup', this._handleCardEmiTenureChange);
    };

    _handleCardBankNameChange = () => {
        this._state.cardBankName = this._cardBankNameElement.value;
        if (this._onChange) {
            this._onChange(this._validator.checkInputValidity(this._state));
        }
    };

    _handleCardEmiTenureChange = () => {
        this._state.emiTenure = this._cardEmiTenureElement.value;
        if (this._onChange) {
            this._onChange(this._validator.checkInputValidity(this._state));
        }
    };

    _setSelectors = () => {
        this._cardNumberElement = this._cardElement.querySelector(EMISelector.CARD_NUMBER);
        this._cardCvvElement = this._cardElement.querySelector(EMISelector.CARD_CVV);
        this._cardExpiryMonthElement = this._cardElement.querySelector(EMISelector.CARD_EXPIRY_MONTH);
        this._cardExpiryYearElement = this._cardElement.querySelector(EMISelector.CARD_EXPIRY_YEAR);
        this._cardBankNameElement = this._cardElement.querySelector(EMISelector.BANK_NAME);
        this._cardEmiTenureElement = this._cardElement.querySelector(EMISelector.EMI_TENURE);
        this._cardHolderElement = this._cardElement.querySelector(EMISelector.CARD_HOLDER);
    };

    isValid = () => {
        const {
            cardNumber,
            cvv,
            cardExpiryMonth,
            cardExpiryYear,
            cardBankName,
            emiTenure,
            cardHolder
        } = this._state;
        if(!cardNumber || !cvv || !cardExpiryMonth || !cardExpiryYear || !cardBankName || !cardHolder || Number(emiTenure) === 0) {
            return false;
        }
        return true;
    }

    pay = async (paymentSessionID, pluginName, locationParent) => {
        this._validator.checkPaymentRequestValidity(this._state);
        const paymentMethod = {
            emi: {
                channel: CARD_LINK,
                card_number: this._state.cardNumber,
                card_expiry_mm: this._state.cardExpiryMonth,
                card_expiry_yy: this._state.cardExpiryYear,
                card_cvv: this._state.cvv,
                card_bank_name: this._state.cardBankName,
                emi_tenure: parseInt(this._state.emiTenure, 10),
            },
        };
        const orderPayResponse = await Order.pay(paymentSessionID, paymentMethod, 'emi', pluginName, locationParent);
        return orderPayResponse.response;
    };
}
