import { generalError } from '../../shared/constants/error-types';
import { CashfreeException } from '../../shared/exception-helper';

const PHONEPE_BASE_PAY_URL = 'https://mercury.phonepe.com/transact/pay';
const PHONEPE_DUMMY_INTENT_URL =
    'upi://pay?pa=mynykaa.collect@yesbank\u0026pn=Cashfree\u0026tr=89407775\u0026am=1.00\u0026cu=INR\u0026mode=00\u0026purpose=00\u0026mc=5399\u0026tn=Cashfree%20Payment';
const INR = 'INR';

const paymentIntentAvailable = () => !!window.PaymentRequest;

const getOrderDetails = (orderCurrency, orderAmount) => ({
    total: {
        label: 'Total',
        amount: {
            currency: orderCurrency,
            value: orderAmount, // sample amount
        },
    },
    displayItems: [
        {
            label: 'Original Amount',
            amount: {
                currency: orderCurrency,
                value: orderAmount,
            },
        },
    ],
});

/**
 *
 * @param {*} supportedInstruments
 * @description In your chrome settings, Access payment methods must be turned on, else it'll always return false
 * @link https://github.com/w3c/payment-request-info/wiki/FAQ#canmakepayment-always-returns-false-why
 * @link https://github.com/stripe-archive/react-stripe-elements/issues/379
 * @returns {boolean} whether instrument(gpay/phonepe) is installed on the device and the user can make a payment.
 */
const checkCanMakePayment = (request) => {
    let canMakePaymentPromise = Promise.resolve(true);

	if (request.canMakePayment) {
		canMakePaymentPromise = request.canMakePayment();
	}
	return canMakePaymentPromise
        // eslint-disable-next-line arrow-body-style
		.then((result) => {
            return result;
        })
		.catch((err) => {
			console.log('Error calling canMakePayment: ' + err);
		});
};

const isPhonePeIntentAvailable = async () => {
    const supportedInstruments = [{
		supportedMethods: [PHONEPE_BASE_PAY_URL],
		data: {
			url: PHONEPE_DUMMY_INTENT_URL,
		},
	}];

	const details = getOrderDetails(INR, '1');
	return new Promise((resolve, reject) => {
		let request = null;
        // eslint-disable-next-line prefer-promise-reject-errors
		if (!paymentIntentAvailable()) reject(false);
		try {
			request = new PaymentRequest(supportedInstruments, details);
		} catch (e) {
            // eslint-disable-next-line prefer-promise-reject-errors
			return reject('Payment Request Error: ' + e.message);
		}
		if (!request) {
            // eslint-disable-next-line prefer-promise-reject-errors
			return reject('Web payments are not supported in this browser.');
		}

		const canMakePaymentPromise = checkCanMakePayment(request);
		canMakePaymentPromise.then((result) => resolve(result)).
        catch((err) => {
			console.log(err);
            // eslint-disable-next-line prefer-promise-reject-errors
			return reject('Error calling checkCanMakePayment: ' + err);
		});
        return '';
	});
};

const isIntentAvailable = async appID => {
    const supportedInstruments = [
        {
            supportedMethods: appID,
            data: {
                pa: 'cashfree@hdfcbank',
                pn: 'Merchant Name',
                tr: '11223344', // Your custom transaction reference ID
                url: 'https://www.cashfree.com',
                mc: '6011', // Your merchant category code
                tn: 'Payment',
            },
        },
    ];

    const details = getOrderDetails(INR, '1');

    return new Promise((resolve, reject) => {
		let request = null;
        // eslint-disable-next-line prefer-promise-reject-errors
		if (!paymentIntentAvailable()) reject(false);
		try {
			request = new PaymentRequest(supportedInstruments, details);
		} catch (e) {
            // eslint-disable-next-line prefer-promise-reject-errors
			return reject('Payment Request Error: ' + e.message);
		}
		if (!request) {
            // eslint-disable-next-line prefer-promise-reject-errors
			return reject('Web payments are not supported in this browser.');
		}

		const canMakePaymentPromise = checkCanMakePayment(request);
		canMakePaymentPromise.then((result) => resolve(result)).catch((err) => {
			console.log(err);
            // eslint-disable-next-line prefer-promise-reject-errors
			return reject('Error calling checkCanMakePayment: ' + err);
		});
        return '';
	});
};

// The following code shows how to display a payment request UI
// and handle the case where the user cannot make payment with Google Pay.
const showPaymentUI = async (request, handleTimeoutCallback) => {
    // Set payment timeout.
    const paymentTimeout = window.setTimeout(() => {
        window.clearTimeout(paymentTimeout);
        request.abort();
        handleTimeoutCallback('Payment timed out after 15 minutes');
    }, 15 * 60 * 1000);

    const paymentResponse = await request.show(); // app will open to initiate payment
    window.clearTimeout(paymentTimeout);

    let msg = 'fail'; // indicating the state of the payment operation upon completion

    // https://developer.mozilla.org/en-US/docs/Web/API/PaymentResponse
    if (paymentResponse.details !== undefined) {
        const { details } = paymentResponse;
        if (details.Status === 'SUCCESS') {
            msg = 'success';
        } else if (details.result !== undefined && details.result.indexOf('Status=Success') > -1) msg = 'success'; // phonepe
    }

    // Notifies the user agent that the user interaction is over.
    // This causes any remaining user interface to be closed.
    await paymentResponse.complete(msg);

    // if msg === 'success', handlePaymentResponse will call openIframeWithSource and onSuccess will be triggered
    // so, no action needed here
    if (msg === 'fail') {
        throw new CashfreeException('[UPI Intent] payment failed', generalError);
    }
};

const createPaymentRequestParams = intentUrl => {
    if (intentUrl.indexOf('pgsim') !== -1 || intentUrl.indexOf('simulator') !== -1) {
        // test mode
        let urlString = intentUrl.split('?')[1];
        urlString = urlString.split('&');
        let amount;
        let pa;
        let pn;
        let tr;
        let mc;
        let tn;
        for(let i =0;i<urlString.length;i+=1) {
            if(urlString[i].indexOf('amount') !== -1) {
                // eslint-disable-next-line prefer-destructuring
                amount = urlString[i].split('=')[1];
            } else if(urlString[i].indexOf('tr') !== -1) {
                // eslint-disable-next-line prefer-destructuring
                tr = urlString[i].split('=')[1];
            } else if(urlString[i].indexOf('pa') !== -1) {
                // eslint-disable-next-line prefer-destructuring
                pa = urlString[i].split('=')[1];
            } else if(urlString[i].indexOf('pn') !== -1) {
                // eslint-disable-next-line prefer-destructuring
                pn = urlString[i].split('=')[1];
            } else if(urlString[i].indexOf('mc') !== -1) {
                // eslint-disable-next-line prefer-destructuring
                mc = urlString[i].split('=')[1];
            } else if(urlString[i].indexOf('tn') !== -1) {
                // eslint-disable-next-line prefer-destructuring
                tn = urlString[i].split('=')[1];
            } 
        }
        return {
            cu: INR,
            am: amount,
            pa,
            pn,
            tr,
            url: 'https://www.cashfree.com',
            mc, // Your merchant category code
            tn,
        };
    }
    return JSON.parse(
        `{"${decodeURI(intentUrl.split('/pay?')[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`
    );
};

const initiatePaymentIntent = async (appName, intentData, orderToken, handleTimeoutCallback) => {
    const supportedInstruments = [
        {
            supportedMethods: appName,
            data: {
                pa: intentData.pa,
                pn: intentData.pn,
                tr: intentData.tr,
                url: `https://payments.cashfree.com/order#${orderToken}`,
                mc: intentData.mc,
                tn: intentData.tn,
            },
        },
    ];

    const details = getOrderDetails(intentData.cu, intentData.am);
    const request = new PaymentRequest(supportedInstruments, details);
    await showPaymentUI(request, handleTimeoutCallback);
};

const initiatePaymentIntentByURL = async (appName, intentData, url, handleTimeoutCallback) => {
    const userAgent = navigator.userAgent.toLowerCase();
    const androidUser = userAgent.indexOf("android") > -1;
    let newUrl = url;
    if(androidUser && (newUrl.indexOf('pgsim') !== -1 || newUrl.indexOf('simulator') !== -1)) {
        const initial = 'upi://';
        const end = newUrl.split('.//')[1];
        newUrl = initial + end;
    }
    const phonepeTestUrl = `upi://pay?pa=mynykaa.collect@yesbank\u0026pn=Cashfree\u0026tr=${intentData.tr}\u0026am=${intentData.am}\u0026cu=INR\u0026mode=00\u0026purpose=00\u0026mc=5399\u0026tn=Cashfree%20Payment`;

    const supportedInstruments = [
        {
            supportedMethods: appName,
            data: {
                url: newUrl.indexOf('pgsim') !== -1 || newUrl.indexOf('simulator') !== -1 ? phonepeTestUrl : newUrl,
            },
        },
    ];

    const details = getOrderDetails(intentData.cu, intentData.am);
    const request = new PaymentRequest(supportedInstruments, details);
    await showPaymentUI(request, handleTimeoutCallback);
};

export {
    createPaymentRequestParams,
    isIntentAvailable,
    isPhonePeIntentAvailable,
    initiatePaymentIntentByURL,
    initiatePaymentIntent,
};
