import { apiError } from '../shared/constants/error-types';
import { CashfreeException } from '../shared/exception-helper';
import { openIframeWithSource } from '../shared/iframe-helper';
import { showLoader, hideLoader } from '../shared/loader';
import { uuidv4 } from '../shared/string-helper';
import Headless from './card/Headless';
import { getDeviceDetails } from '../shared/device-helper';

export class Order {
    static pay = async (paymentSessionID, paymentMethod, mode, pluginName, locationParent) => {
        const xRequestId = uuidv4();
        const statusToken = `${paymentSessionID}${xRequestId}`;
        const { deviceType, browserType, browserVersion, osType, osVersion } = getDeviceDetails();
        const platform = `${pluginName}-${deviceType}-${browserType}-${browserVersion}-${osType}-${osVersion}`;
        const requestPayload = {
            payment_session_id: paymentSessionID,
            payment_method: paymentMethod,
            platform,
        };
        const windowSize = {
            width: 800,
            height: 600,
        };
        const windowLocation = {
            left: window.screen.availLeft + window.screen.availWidth / 2 - windowSize.width / 2,
            top: window.screen.availTop + window.screen.availHeight / 2 - windowSize.height / 2,
        };

        const paymentWindow = window.open(
            '',
            '_blank',
            `toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=${windowSize.width},height=${windowSize.height},top=${windowLocation.top},left=${windowLocation.left}`
        );
        window.paymentWindow = paymentWindow;
        const response = await fetch(`${API_HOST_URL}/orders/sessions/js`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-request-id': xRequestId,
                'x-document-referrer': locationParent || document.referrer,
            },
            body: JSON.stringify(requestPayload),
        });

        if (response.status !== 200) {
            const failedRes = await response.json();
            throw new CashfreeException(failedRes.message, apiError, mode, failedRes.code);
        }

        return {
            response: response.json(),
            statusToken,
        };
    };

    static intentPay = async (paymentSessionID, paymentMethod, mode, pluginName, locationParent) => {
        const xRequestId = uuidv4();
        const statusToken = `${paymentSessionID}${xRequestId}`;
        const { deviceType, browserType, browserVersion, osType, osVersion } = getDeviceDetails();
        const platform = `${pluginName}-${deviceType}-${browserType}-${browserVersion}-${osType}-${osVersion}`;
        const requestPayload = {
            payment_session_id: paymentSessionID,
            payment_method: paymentMethod,
            platform,
        };

        const response = await fetch(`${API_HOST_URL}/orders/sessions/js`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-request-id': xRequestId,
                'x-document-referrer':locationParent || document.referrer,
            },
            body: JSON.stringify(requestPayload),
        });

        if (response.status !== 200) {
            const failedRes = await response.json();
            throw new CashfreeException(failedRes.message, apiError, mode);
        }

        return {
            response: response.json(),
            statusToken,
        };
    };

    static handlePaymentResponse = (response, paymentMethod, statusToken, abortPaymentCallback, payHeadless) => {
        const { paymentWindow } = window;
        if (response.action === 'link') {
            if (paymentWindow) {
                paymentWindow.location = response.data.url;
            }
            showLoader(paymentWindow, abortPaymentCallback);
            this.endAndStartTimer(abortPaymentCallback);

            if (paymentWindow) {
                paymentWindow.focus();
            }
            return paymentWindow;
        }
        if (paymentWindow) {
            paymentWindow.close();
        }
        if (response.action === 'post') {
            const headless = new Headless();
            headless.renderHeadless(response, abortPaymentCallback, payHeadless);
            return null;
        }
        if (response.action === 'custom' && !document.getElementById('cashfree-iframe-container')) {
            // no polling running already
            showLoader(null, abortPaymentCallback);
            openIframeWithSource('0', `${REDIRECT_HOST_URL}/status/${paymentMethod}/${statusToken}`);
        }
        return null;
    };

    timer = '';

    static endAndStartTimer = abortPaymentCallback => {
        const { paymentWindow } = window;
        window.clearTimeout(this.timer);
        this.timer = window.setTimeout(() => {
            hideLoader();
            if (abortPaymentCallback) {
                abortPaymentCallback();
            }
            if (paymentWindow) {
                paymentWindow.close();
            }
        }, 1000 * 60 * 10);
    };
}
