import { DOMErrors } from '../../shared/constants/error-codes';
import { inputValidationError } from '../../shared/constants/error-types';
import { AppSelector } from '../../shared/constants/selectors';
import { CashfreeException } from '../../shared/exception-helper';
import { phoneNumberRegex } from '../../shared/regex';
import { initialiseValidationCallbackData, isReadyToPay } from '../../shared/validation-helper';

const isValidPhoneNumber = phone => phoneNumberRegex.test(phone);

const checkInputValidity = paymentRequest => {
    const validityData = initialiseValidationCallbackData([AppSelector.PHONE, AppSelector.APP_NAME]);

    if (!isValidPhoneNumber(paymentRequest.phoneNumber)) {
        validityData.errors[0].error = true;
        validityData.errors[0].message = 'invalid phone number';
    }

    if (!paymentRequest.appName) {
        validityData.errors[1].error = true;
        validityData.errors[1].message = 'invalid app name';
    }

    validityData.isReadyToPay = isReadyToPay(validityData.errors);
    return validityData;
};

const checkElementsValidity = appElementWrapper => {
    if (appElementWrapper.querySelector(AppSelector.PHONE) === null) {
        throw new CashfreeException(DOMErrors.APP_PHONE_NUMBER_ELEMENT_NOT_FOUND);
    }
    if (appElementWrapper.querySelector(AppSelector.APP_NAME) === null) {
        throw new CashfreeException(DOMErrors.APP_NAME_ELEMENT_NOT_FOUND);
    }
};

const checkPaymentRequestValidity = paymentRequest => {
    if (!isValidPhoneNumber(paymentRequest.phoneNumber)) {
        throw new CashfreeException('invalid phone number', inputValidationError, 'app');
    }
    if (!paymentRequest.appName) {
        throw new CashfreeException('invalid app name', inputValidationError, 'app');
    }
};

export { checkElementsValidity, checkInputValidity, checkPaymentRequestValidity };
