import FingerprintJS from "@fingerprintjs/fingerprintjs"; 
import platform from "platform";

export default {
    async track(etype, eprops, orderHash = "") {
		if (etype === "" || orderHash === "") {
            return;
        }
		
		const fp = await FingerprintJS.load();
		const result = await fp.get();
		const extendedComponents = {
            ...result.components,
            userString: { value: ''},
        };
        const visitorId = FingerprintJS.hashComponents(extendedComponents);
		const browserData = platform.parse(window.navigator.userAgent);
		const platformType = document.getElementsByTagName("body")[0].getAttribute("view") || "mobile";
		const browserName =  browserData.name ? browserData.name : "NoBrowser";
		const osName = browserData.os.family ? browserData.os.family : "NoOS";
        // eslint-disable-next-line camelcase
		const os_name = browserName + "-" + osName;
		let browserVersion = browserData.version ? browserData.version : "0.0.1";
		[browserVersion] = browserVersion.split(".");
		const tracker = {
            user_id: visitorId,
            device_id: visitorId,
            os_name,
            os_version: browserVersion,
            platform: platformType || "NoPlatform",
            device_brand: browserData.manufacturer
                ? browserData.manufacturer
                : "NA",
            device_model: browserData.product
                ? browserData.product
                : "NA",
            language: navigator.language || navigator.userLanguage,
            event_type: "PGA " + etype.toLowerCase(),
            app_version: "CoreJs",
        };
		
		if(eprops) {
			tracker.event_properties = eprops
		}

		const base64Data = btoa(JSON.stringify(tracker));
		fetch(`${AMPLITUDE_URL}/analytics/${orderHash}/track`, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
                data: base64Data,
            }),
        });
    },
    log (logs) {
        console.log(logs);
    }
}