import { CacheFirst } from '../shared/api-strategy';
import { generalError } from '../shared/constants/error-types';
import { isMobile } from '../shared/device-helper';
import { CashfreeException } from '../shared/exception-helper';
import constants from '../shared/constants/payment-limits';

const isCardSupported = paymentModes =>
    paymentModes.CREDIT_CARD ||
    paymentModes.DEBIT_CARD ||
    paymentModes.CORPORATE_CREDIT_CARD ||
    paymentModes.PREPAID_CARD;

const isAppSupported = paymentModes => {
    if (paymentModes.Wallet || paymentModes.App) return true;
    return false;
};

const isNetBankingSupported = paymentModes => {
    if (paymentModes.NET_BANKING) return true;
    return false;
};

const isUpiSupported = paymentModes => {
    if (paymentModes.UPI) return true;
    return false;
};

const isPaylaterSupported = paymentModes => {
    if (paymentModes.PAY_LATER) return true;
    return false;
};

const isCreditEmiSupported = (creditEmiConfig,creditModes) => {
    let count = 0;
    if (creditEmiConfig && creditModes) {
        creditEmiConfig.forEach(bank => {
            if (creditModes.some(mode => mode.code === bank.paymentCode)){
                count+=1;
            }
        });
    }
    return count > 0;
};

const isDebitEmiSupported = (debitEmiConfig,debitModes) => {
    let count = 0;
    if (debitEmiConfig && debitModes) {
        debitEmiConfig.forEach(bank => {
            if (debitModes.some(mode => mode.code === bank.paymentCode)){
                count+=1;
            }
        });
    }
    return count > 0;
};

const isCardlessEMISupported = paymentModes => {
    if (paymentModes.CARDLESS_EMI) {
        // eslint-disable-next-line no-restricted-syntax
        for (const mode of paymentModes.CARDLESS_EMI) {
            if (['flexmoney', 'zestmoney'].includes(mode.nick)) {
                return true;
            }
        }
    }
    return false;
};

const getSupportedApps = appConfig => {
    const supportedApps = [];
    appConfig.forEach(app => {
        if (app.isActive) {
            supportedApps.push({
                dataOption: app.nick,
                display: app.display,
                optionCode: app.code,
            });
        }
    });
    return supportedApps;
};

const getSupportedNetBankingOptions = netBankingConfig => {
    const supportedNetBankingOptions = [];
    netBankingConfig.forEach(netBankingOption => {
        if (netBankingOption.isActive) {
            supportedNetBankingOptions.push({
                dataOption: netBankingOption.code,
                display: netBankingOption.display,
                isActive: netBankingOption.isActive,
                nick: netBankingOption.nick,
            });
        }
    });
    return supportedNetBankingOptions;
};

const getSupportedCards = (debitCardConfig, creditCardConfig) => {
    const supportedCards = [];
    debitCardConfig.forEach(card => {
        if (card.isActive) {
            supportedCards.push({
                dataOption: card.code,
                display: card.display,
            });
        }
    });

    creditCardConfig.forEach(card => {
        if (card.isActive) {
            supportedCards.push({
                dataOption: card.code,
                display: card.display,
            });
        }
    });

    return supportedCards;
};

const getSupportedUpiApps = async () => {
    const supportedUpiApps = [];
    if (isMobile()) {
        supportedUpiApps.push({ dataOption: 'phonepe', display: 'PhonePe' });
        supportedUpiApps.push({ dataOption: 'gpay', display: 'GPay' });
        supportedUpiApps.push({ dataOption: 'paytm', display: 'Paytm' });
        supportedUpiApps.push({ dataOption: 'bhim', display: 'Bhim' });
        const userAgent = navigator.userAgent.toLowerCase();
        const androidUser = userAgent.indexOf('android') > -1;
        if (androidUser) {
            supportedUpiApps.push({ dataOption: 'others', display: 'Others' });
        }
    }
    return supportedUpiApps;
};

const getSupportedPaylaterOptions = (paylaterConfig, orderAmount) => {
    const supportedProviders = [];
    const paymentCodeLimit = constants.PAYMENT_CODE_LIMIT.PAY_LATER;
    paylaterConfig.forEach(app => {
        const minAmount = paymentCodeLimit[app.code] && paymentCodeLimit[app.code].MIN_AMOUNT;
        const maxAmount = paymentCodeLimit[app.code] && paymentCodeLimit[app.code].MAX_AMOUNT;
        if (orderAmount < minAmount || orderAmount > maxAmount) {
            return;
        }
        if (app.isActive && app.nick !== 'epaylater') {
            supportedProviders.push({
                dataOption: app.nick,
                display: app.display,
            });
        }
    });
    return supportedProviders;
};

const getSupportedCreditEmiOptions = (creditEmiConfig, creditModes) => {
    const supportedBanks = [];
    if (creditEmiConfig) {
        creditEmiConfig.forEach(bank => {
            if (creditModes.some(mode => mode.code === bank.paymentCode)){
                supportedBanks.push({
                    dataOption: bank.nick,
                    display: bank.name,
                    schemes: bank.schemes,
                });
            }
        });
    }
    return supportedBanks;
};

const getSupportedDebitEmiOptions = (debitEmiConfig, debitModes) => {
    const supportedBanks = [];
    if (debitEmiConfig) {
        debitEmiConfig.forEach(bank => {
            if (debitModes.some(mode => mode.code === bank.paymentCode)){
                supportedBanks.push({
                    dataOption: bank.nick,
                    display: bank.name,
                    schemes: bank.schemes,
                });
            }
        });
    }
    return supportedBanks;
};

const getSupportedCardlessOptions = cardlessConfig => {
    const supportedCardless = [];
    if (cardlessConfig.some(emi => emi.nick === 'flexmoney')) {
        supportedCardless.push({
            dataOption: 'flexmoney',
            display: 'FlexMoney',
        });
    }
    if (cardlessConfig.some(emi => emi.nick === 'zestmoney')) {
        supportedCardless.push({
            dataOption: 'zestmoney',
            display: 'ZestMoney',
        });
    }
    return supportedCardless;
};

const SlicePaymentModes = (showModes, enabledModes) => {
    const orderModes = {
        upi: 'UPI',
        dc: 'DEBIT_CARD',
        cc: 'CREDIT_CARD,PREPAID_CARD,CORPORATE_CREDIT_CARD',
        ppc: 'PREPAID_CARD',
        ccc: 'CORPORATE_CREDIT_CARD',
        nb: 'NET_BANKING',
        wallet: 'Wallet',
        app: 'Wallet',
        emi: 'CREDIT_CARD_EMI,DEBIT_CARD_EMI,CARDLESS_EMI',
        paypal: 'Paypal',
        paylater: 'PAY_LATER',
    };
    let newEnabledModes = [];
    try {
        if (showModes && showModes.length > 0) {
            showModes = showModes.split(',');
            for (let i = 0; i < showModes.length; i += 1) {
                const mode = showModes[i].trim();

                const needMode = orderModes[mode].split(',');
                for (let j = 0; j < needMode.length; j += 1) {
                    if (enabledModes.indexOf(needMode[j]) > -1 && newEnabledModes.indexOf(needMode[j]) === -1)
                        newEnabledModes.push(needMode[j]);
                }
            }
        } else newEnabledModes = enabledModes;

        return newEnabledModes;
    } catch (err) {
        return enabledModes;
    }
};

const processModes = (enabledModes, paymentModes) => {
    Object.keys(paymentModes).forEach(mode => {
        if (enabledModes.indexOf(mode) === -1) {
            paymentModes[mode] = null;
        }
    });
    return paymentModes;
};

class PaymentConfig {
    static getSupportedPaymentOptions = async (paymentSessionId, paymentMode) => {
        if (!paymentSessionId) {
            throw new CashfreeException('paymentSessionId missing', generalError);
        }
        if (!paymentMode) {
            throw new CashfreeException('payment mode missing', generalError);
        }
        const orderConfigJson = await CacheFirst(`${API_HOST_URL}/orders/${paymentSessionId}/config`);
        switch (paymentMode) {
            case 'card':
                return getSupportedCards(
                    orderConfigJson.paymentSettings.paymentModes.DEBIT_CARD,
                    orderConfigJson.paymentSettings.paymentModes.CREDIT_CARD
                );
            case 'app':
                return getSupportedApps(orderConfigJson.paymentSettings.paymentModes.Wallet);
            case 'netbanking':
                return getSupportedNetBankingOptions(orderConfigJson.paymentSettings.paymentModes.NET_BANKING);
            case 'upi-intent':
                return getSupportedUpiApps();
            case 'paylater':
                return getSupportedPaylaterOptions(
                    orderConfigJson.paymentSettings.paymentModes.PAY_LATER,
                    orderConfigJson.orderDetails.orderAmount
                );
            case 'creditcardemi':
                return getSupportedCreditEmiOptions(
                    orderConfigJson.emiDetails,
                    orderConfigJson.paymentSettings.paymentModes.CREDIT_CARD_EMI
                );
            case 'debitcardemi':
                return getSupportedDebitEmiOptions(
                    orderConfigJson.emiDetails,
                    orderConfigJson.paymentSettings.paymentModes.DEBIT_CARD_EMI
                );
            case 'cardlessemi':
                return getSupportedCardlessOptions(orderConfigJson.paymentSettings.paymentModes.CARDLESS_EMI);
            default:
                return [];
        }
    };

    static getSupportedPaymentModes = async paymentSessionId => {
        if (!paymentSessionId) {
            throw new CashfreeException('paymentSessionId missing', generalError);
        }
        const supportedPaymentModes = [];
        const orderConfigJson = await CacheFirst(`${API_HOST_URL}/orders/${paymentSessionId}/config`);
        const currentTime = new Date().getTime();
        const expiryTime = new Date(orderConfigJson.orderDetails.orderExpiryTime).getTime();
        if (orderConfigJson.orderDetails.orderStatus === 'PAID' || expiryTime - currentTime <= 0) {
            const modifiedData = {
                orderData: {
                    order: {
                        activePaymentMethod: null,
                        errorText: null,
                        message: expiryTime - currentTime <= 0 ? 'Order is no longer active' : 'Order is already Paid',
                        orderId: orderConfigJson.orderDetails.orderId,
                        status: orderConfigJson.orderDetails.orderStatus,
                    },
                    transaction: null,
                },
                randomId: 'Initial',
            };
            window.parent.postMessage(modifiedData, '*');
        } else {
            const {
                paymentSettings: { paymentModes: availablePaymentModes, enabledModes: availableEnabledModes },
                emiDetails,
                orderDetails,
            } = orderConfigJson;
            const enabledModes = SlicePaymentModes(orderDetails.paymentModes, availableEnabledModes);
            const paymentModes = processModes(enabledModes, availablePaymentModes);
            if (isCardSupported(paymentModes)) {
                supportedPaymentModes.push('card');
            }
            if (isAppSupported(paymentModes)) {
                supportedPaymentModes.push('app');
            }
            if (isNetBankingSupported(paymentModes)) {
                supportedPaymentModes.push('netbanking');
            }
            if (isUpiSupported(paymentModes)) {
                supportedPaymentModes.push('upi-qrcode');
                supportedPaymentModes.push('upi-collect');
                if (isMobile()) {
                    supportedPaymentModes.push('upi-intent');
                }
                supportedPaymentModes.push('upi');
            }
            if (isCreditEmiSupported(emiDetails, paymentModes.CREDIT_CARD_EMI) && orderDetails.orderAmount >= 2500) {
                supportedPaymentModes.push('creditcardemi');
            }
            if (isDebitEmiSupported(emiDetails, paymentModes.DEBIT_CARD_EMI) && orderDetails.orderAmount >= 2500) {
                supportedPaymentModes.push('debitcardemi');
            }
            if (isPaylaterSupported(paymentModes)) {
                supportedPaymentModes.push('paylater');
            }
            if (isCardlessEMISupported(paymentModes) && orderDetails.orderAmount >= 500) {
                supportedPaymentModes.push('cardlessemi');
            }
            return supportedPaymentModes;
        }
        return [];
    };

    static getOrderDetails = async paymentSessionId => {
        if (!paymentSessionId) {
            throw new CashfreeException('paymentSessionId missing', generalError);
        }
        const orderConfigJson = await CacheFirst(`${API_HOST_URL}/orders/${paymentSessionId}/config`);
        return orderConfigJson.orderDetails;
    };

    static getTheme = async paymentSessionId => {
        if (!paymentSessionId) {
            throw new CashfreeException('paymentSessionId missing', generalError);
        }
        const themeJson = await CacheFirst(`${API_HOST_URL}/orders/${paymentSessionId}/config`);
        const { theme, merchantInfo, orderDetails, clientInfo, isCollectEnabled } = themeJson;
        return { theme, merchantInfo, orderDetails, clientInfo, isCollectEnabled };
    };
}

export default PaymentConfig;
