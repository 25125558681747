import { AppSelector } from '../../shared/constants/selectors';
import { openIframeWithSource } from '../../shared/iframe-helper';
import { Order } from '../Order';
import { checkElementsValidity, checkInputValidity, checkPaymentRequestValidity } from './AppValidation';
import analytics from "../../analytics";

export default class App {
    constructor(appElement, onChange) {
        this._appElement = appElement;
        this._onChange = onChange;
        this._state = {
            phoneNumber: '',
            appName: '',
        };
        this._setSelector();
        checkElementsValidity(appElement);
        this._syncState();
        this._registerListener();
        analytics.track("open_app");
    }

    _syncState = () => {
        this._state.phoneNumber = this._phoneNumberElement.value;
        this._state.appName = this._appNameElement.value;
    };

    _setSelector = () => {
        this._phoneNumberElement = this._appElement.querySelector(AppSelector.PHONE);
        this._appNameElement = this._appElement.querySelector(AppSelector.APP_NAME);
    };

    _registerListener = () => {
        this._phoneNumberElement.addEventListener('keyup', this._handlePhoneNumberChange);
        this._phoneNumberElement.addEventListener('change', this._handlePhoneNumberChange);
        this._appNameElement.addEventListener('change', this._handleAppNameChange.bind(this));
    };

    _handlePhoneNumberChange = () => {
        this._state.phoneNumber = this._phoneNumberElement.value;
        if (this._onChange) {
            this._onChange(checkInputValidity(this._state));
        }
    };

    _handleAppNameChange = () => {
        this._state.appName = this._appNameElement.value;
        if (this._onChange) {
            this._onChange(checkInputValidity(this._state));
        }
    };

    isValid = () => {
        if(!this._state.appName) {
            return false;
        }
        return true;
    }

    pay = async (paymentSessionID, pluginName, locationParent) => {
        this._syncState();
        checkPaymentRequestValidity(this._state);

        const paymentMethod = {
            app: {
                channel: this._state.appName,
                phone: this._state.phoneNumber,
            },
        };

        const orderPayResponse = await Order.pay(paymentSessionID, paymentMethod, 'app', pluginName, locationParent);
        this._statusToken = orderPayResponse.statusToken;
        return orderPayResponse.response;
    };

    handlePaymentResponse = (response, abortPaymentCallback, paymentData) => {
        paymentData({...this._state, paymentMode: 'app'});
        if (response.action === 'link') {
            openIframeWithSource('0', `${REDIRECT_HOST_URL}/status/gpay/${this._statusToken}`); // Poll for status irrespective of action
        }

        return Order.handlePaymentResponse(response, this._state.channel, this._statusToken, abortPaymentCallback);
    };
}
