/**
 * User flow:
 *  User comes to Cashfree checkout page
 *  User selects UPI as payment mode and enters VPA
 *  Gets notification
 *  Makes payment
 */
import { inputValidationError } from '../../shared/constants/error-types';
import { UPISelector } from '../../shared/constants/selectors';
import { CashfreeException } from '../../shared/exception-helper';
import { upiIdRegex } from '../../shared/regex';
import { Order } from '../Order';
import { checkCollectInputValidity, checkCollectElementsValidity } from './UPIValidation';
import analytics from "../../analytics";

export default class Collect {
    constructor(collectElement, onChange) {
        this._collectElement = collectElement;
        this._onChange = onChange;
        this._state = {
            upiId: '',
        };

        checkCollectElementsValidity(collectElement);
        this._setSelector();
        this._syncState();
        this._registerListeners();
        analytics.track("open_collect");
    }

    _syncState = () => {
        this._state.upiId = this._vpaElement.value;
    };

    _setSelector = () => {
        this._vpaElement = this._collectElement.querySelector(UPISelector.COLLECT_VPA);
    };

    _registerListeners = () => {
        this._vpaElement.addEventListener('change', this._handleVpaChange);
        this._vpaElement.addEventListener('keyup', this._handleVpaChange);
        this._vpaElement.addEventListener('click', this._handleVpaChange);
    };

    _handleVpaChange = () => {
        this._state.upiId = this._vpaElement.value;
        if (this._onChange) {
            this._onChange(checkCollectInputValidity(this._state));
        }
    };

    isValid = () => {
        if(!this._state.upiId || !upiIdRegex.test(this._state.upiId)) {
            return false;
        }
        return true;
    }

    pay = async (paymentSessionID, pluginName, locationParent) => {
        if (!upiIdRegex.test(this._state.upiId)) {
            throw new CashfreeException('invalid vpa', inputValidationError, 'upi-collect');
        }

        const paymentMethod = {
            upi: {
                channel: 'collect',
                upi_id: this._state.upiId,
            },
        };

        const orderPayResponse = await Order.intentPay(paymentSessionID, paymentMethod, 'upi-collect', pluginName, locationParent);
        this._statusToken = orderPayResponse.statusToken;

        return orderPayResponse.response;
    };

    handlePaymentResponse = (response, abortPaymentCallback, paymentData) => {
        paymentData({...this._state, paymentMode: 'upi-collect'});
        return  Order.handlePaymentResponse(response, 'collect', this._statusToken, abortPaymentCallback);
    }
}
