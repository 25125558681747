import { domError, inputValidationError } from '../../shared/constants/error-types';
import { DOMErrors } from '../../shared/constants/error-codes';
import { CashfreeException } from '../../shared/exception-helper';
import { UPISelector } from '../../shared/constants/selectors';
import { upiIdRegex } from '../../shared/regex';
import { initialiseValidationCallbackData, isReadyToPay } from '../../shared/validation-helper';

const isValidUpiProvider = provider => {
    const validProvidersList = ['gpay', 'phonepe', 'paytm', 'bhim', 'others'];
    return validProvidersList.indexOf(provider) !== -1;
};

const isValidUpiId = upiId => upiIdRegex.test(upiId);

const checkCollectInputValidity = paymentRequest => {
    const validityData = initialiseValidationCallbackData([UPISelector.COLLECT_VPA]);

    if (!isValidUpiId(paymentRequest.upiId)) {
        validityData.errors[0].error = true;
        validityData.errors[0].message = 'invalid upi id';
    }

    validityData.isReadyToPay = isReadyToPay(validityData.errors);
    return validityData;
};

const checkIntentInputValidity = paymentRequest => {
    const validityData = initialiseValidationCallbackData([UPISelector.INTENT_PROVIDER]);

    if (!isValidUpiProvider(paymentRequest.upiProvider)) {
        validityData.errors[0].error = true;
        validityData.errors[0].message = 'invalid upi provider';
    }

    validityData.isReadyToPay = isReadyToPay(validityData.errors);
    return validityData;
};

const checkCollectElementsValidity = vpaElement => {
    if (!vpaElement.querySelector(UPISelector.COLLECT_VPA)) {
        throw new CashfreeException(DOMErrors.UPI_COLLECT_VPA_ELEMENT_NOT_FOUND, domError);
    }
};

const checkIntentElementsValidity = intentElement => {
    if (!intentElement.querySelector(UPISelector.INTENT_PROVIDER)) {
        throw new CashfreeException(DOMErrors.UPI_INTENT_PROVIDER_ELEMENT_NOT_FOUND, domError);
    }
};

const checkIntentPaymentRequestValidity = paymentRequest => {
    if (!isValidUpiProvider(paymentRequest.upiProvider)) {
        throw new CashfreeException('no UPI provider provided.', inputValidationError);
    }
};

export {
    isValidUpiProvider,
    checkCollectInputValidity,
    checkCollectElementsValidity,
    checkIntentElementsValidity,
    checkIntentInputValidity,
    checkIntentPaymentRequestValidity,
};
