import '../../Headless.css';
import { uuidv4 } from '../../shared/string-helper';
import { NetworkOnly } from '../../shared/api-strategy';

export default class Headless {
    getBrandIcon = brand => {
        switch (brand) {
            case 'visa':
                return 'https://js.stripe.com/v3/fingerprinted/img/visa-365725566f9578a9589553aa9296d178.svg';
            case 'americanexpress':
                return 'https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg';
            case 'mastercard':
                return 'https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg';
            default:
                return <> </>;
        }
    };

    renderCrossIcon = () =>
        '<svg class="headless-close-modal" width="14px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g id="Cross" transform="translate(-5.000000, -5.000000)" stroke="#434343" stroke-width="3"><g id="back-arrow"><g id="Cross" transform="translate(6.000000, 6.000000)"><polyline id="Path-6" points="24 -7.07767178e-15 12 12 24 24"></polyline><polyline id="Path-6" transform="translate(6.000000, 12.000000) scale(-1, 1) translate(-6.000000, -12.000000) " points="12 -7.07767178e-15 0 12 12 24"></polyline></g></g></g></g></svg>';

    renderBankIcon = () =>
        `<svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
            <path d="M1.25066 13.75C1.33775 13.8373 1.4412 13.9066 1.5551 13.9538C1.669 14.0011 1.7911 14.0254 1.91441 14.0254C2.03773 14.0254 2.15983 14.0011 2.27373 13.9538C2.38762 13.9066 2.49108 13.8373 2.57816 13.75L8.81066 7.51751C8.95035 7.37699 9.02876 7.1869 9.02876 6.98876C9.02876 6.79062 8.95035 6.60053 8.81066 6.46001L2.57816 0.250011C2.40001 0.0898272 2.1673 0.00392426 1.9278 0.0099394C1.6883 0.0159545 1.46019 0.113431 1.29031 0.282356C1.12042 0.451281 1.02165 0.678834 1.01428 0.918296C1.00691 1.15776 1.09149 1.39096 1.25066 1.57001L6.68066 7.00001L1.25066 12.445C1.08113 12.6199 0.986328 12.8539 0.986328 13.0975C0.986328 13.3411 1.08113 13.5751 1.25066 13.75Z" fill="#6930CA"/>
        </svg>`;

    renderHeadless = (response, abortPaymentCallback, payHeadless) => {
        // eslint-disable-next-line camelcase
        const { bankData, payment_amount } = response;
        let otpValue = '';

        // prepare overlay
        const overlayDiv = document.createElement('div');
        document.body.style.overflow = 'hidden';
        overlayDiv.innerHTML = `
            <div class="headless-modal">
                <div id="headless" class="headless-container">
                    <div id="headless-loader"class="headless-loader">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%; display: block; shape-rendering: auto;" width="81px" height="81px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <circle cx="84" cy="50" r="10" fill="#ffb6bb">
                                <animate attributeName="r" repeatCount="indefinite" dur="0.6756756756756757s" calcMode="spline" keyTimes="0;1" values="8;0" keySplines="0 0.5 0.5 1" begin="0s"/>
                                <animate attributeName="fill" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="discrete" keyTimes="0;0.25;0.5;0.75;1" values="#ffb6bb;#585872;#95d5ee;#ffe691;#ffb6bb" begin="0s"/>
                            </circle><circle cx="16" cy="50" r="10" fill="#ffb6bb">
                            <animate attributeName="r" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;8;8;8" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"/>
                            <animate attributeName="cx" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"/>
                            </circle><circle cx="50" cy="50" r="10" fill="#ffe691">
                            <animate attributeName="r" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;8;8;8" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.6756756756756757s"/>
                            <animate attributeName="cx" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.6756756756756757s"/>
                            </circle><circle cx="84" cy="50" r="10" fill="#95d5ee">
                            <animate attributeName="r" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;8;8;8" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.3513513513513513s"/>
                            <animate attributeName="cx" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.3513513513513513s"/>
                            </circle><circle cx="16" cy="50" r="10" fill="#585872">
                            <animate attributeName="r" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;8;8;8" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-2.027027027027027s"/>
                            <animate attributeName="cx" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-2.027027027027027s"/>
                            </circle>
                        </svg>
                    </div>
                    <div class="d-flex-cross">
                        <div class="right-div">${this.renderCrossIcon()}</div>
                    </div>
                    <div class="otp-data">
                        <div class="otp-text">Enter One Time Password</div>
                        ${
                            bankData && Object.keys(bankData).length
                                ? `<div class="card-details">
                            <div class="card-data mb-10">
                                <img src=${this.getBrandIcon(
                                    bankData.brand
                                )} style="padding-right: 0.5rem;width: 36px; margin-bottom: 2px;"/>
                                <span class="card-title">Card Number:</span> 
                                ${bankData.cardNumber.slice(0, 4)} XXXX XXXX ${bankData.cardNumber.slice(-4)}
                            </div>
                        </div>`
                                : ''
                        }
                        <div class="otp-subtext">Please enter OTP sent to your registered mobile number</div>
                        <div class="otp-input-div">
                            <input autocomplete="one-time-code" class="otp-input" id="headless-otp-input" placeholder="Enter OTP"/>
                            ${
                                bankData && Object.keys(bankData).length
                                    ? `<div id="headless-otp-resend" class="otp-input-resend">Resend OTP </div>`
                                    : '<div id="headless-otp-resend" class="otp-input-resend"></div>'
                            }
                        </div>
                        <div id="error-msg" class="error-msg"></div> 
                        <div class="btn-div">
                            <button id="headless-cancel" class="btn cancel-btn">Cancel</button>
                            <button id="headless-submit" class="btn submit-btn" disabled>Submit</button>
                        </div>
                    </div>x
                </div>
            </div>
        `;

        // inject overlay to DOM
        document.body.appendChild(overlayDiv);
        const closeHeadlessModal = hideError => {
            if (document.body.style.removeProperty) {
                document.body.style.removeProperty('overflow');
            } else {
                document.body.style.removeAttribute('overflow');
            }
            document.body.removeChild(overlayDiv);
            abortPaymentCallback(hideError);
        };
        const closeIcon = document.getElementsByClassName('headless-close-modal')[0];
        const submitBtn = document.getElementById('headless-submit');
        if (closeIcon) {
            closeIcon.addEventListener('click', () => {
                closeHeadlessModal();
            });
        }
        document.getElementById('headless-cancel').addEventListener('click', () => {
            closeHeadlessModal();
        });
        document.getElementById('headless-otp-input').addEventListener('input', e => {
            const {
                target: { value },
            } = e;
            otpValue = value;
            if (value.length < 4) {
                submitBtn.disabled = true;
            } else {
                submitBtn.disabled = false;
            }
        });
        document.getElementById('headless-otp-resend').addEventListener('click', async () => {
            const loader = document.getElementById('headless-loader');
            const xRequestId = uuidv4();
            let errMsg = '';
            try {
                submitBtn.disabled = true;
                loader.style.display = 'flex';
                const {
                    data: { url },
                } = response;
                const data = {
                    action: 'RESEND_OTP',
                };
                const apiResponse = await NetworkOnly(url, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                        'x-request-id': xRequestId,
                        // 'x-document-referrer': document.referrer,
                    },
                });
                if (apiResponse.authenticate_status !== 'SUCCESS') {
                    errMsg = 'Failed to send OTP';
                }
            } catch (e) {
                errMsg = e.message || 'Something went wrong';
                if (e.apiCode === 'request_invalid') {
                    document.getElementById('headless-otp-resend').style.display = 'none';
                }
            } finally {
                submitBtn.disabled = false;
                loader.style.display = 'none';
                const errDiv = document.getElementById('error-msg');
                if (errDiv) {
                    if (errMsg) {
                        errDiv.innerHTML = errMsg;
                    } else {
                        errDiv.innerHTML = '';
                    }
                }
            }
        });
        submitBtn.addEventListener('click', async () => {
            const loader = document.getElementById('headless-loader');
            let errMsg = '';
            const xRequestId = uuidv4();
            try {
                submitBtn.disabled = true;
                loader.style.display = 'flex';
                const {
                    data: { url },
                } = response;
                const data = {
                    otp: otpValue,
                    action: 'SUBMIT_OTP',
                };
                const apiResponse = await NetworkOnly(url, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                        'x-request-id': xRequestId,
                        // 'x-document-referrer': document.referrer,
                    },
                });
                if (apiResponse.authenticate_status === 'SUCCESS') {
                    const responseData = {
                        transaction: {
                            txStatus: 'SUCCESS',
                            txMsg: '',
                            transactionId: apiResponse.cf_payment_id,
                            transactionAmount: payment_amount,
                        },
                    };
                    closeHeadlessModal(true);
                    await payHeadless(responseData);
                } else {
                    errMsg = apiResponse.payment_message || 'Something went wrong';
                }
            } catch (e) {
                errMsg = e.message || 'Something went wrong';
                if (e.apiCode === 'request_invalid' || errMsg.toUpperCase() === "MAX OTP ATTEMPT REACHED") {
                    setTimeout(() => {
                        closeHeadlessModal(true);
                    }, 2000);
                }
            } finally {
                submitBtn.disabled = false;
                loader.style.display = 'none';
                const errDiv = document.getElementById('error-msg');
                if (errDiv) {
                    if (errMsg) {
                        errDiv.innerHTML = errMsg;
                    } else {
                        errDiv.innerHTML = '';
                    }
                }
            }
        });
    };
}
