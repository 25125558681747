import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { isMobile } from './device-helper';

/**
 *
 * @link https://codepen.io/aaroniker/pen/ZmOMJp
 */
const getLoaderStyle = () => {
    const style = `
        <style>
          .boxes {
            --size: 32px;
            --duration: 800ms;
            height: calc(var(--size) * 2);
            width: calc(var(--size) * 3);
            position: relative;
            transform-style: preserve-3d;
            transform-origin: 50% 50%;
            margin-top: calc(var(--size) * 1.5 * -1);
            transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
          }
          .boxes .box {
            width: var(--size);
            height: var(--size);
            top: 0;
            left: 0;
            position: absolute;
            transform-style: preserve-3d;
          }
          .boxes .box:nth-child(1) {
            transform: translate(100%, 0);
            -webkit-animation: box1 var(--duration) linear infinite;
                    animation: box1 var(--duration) linear infinite;
          }
          .boxes .box:nth-child(2) {
            transform: translate(0, 100%);
            -webkit-animation: box2 var(--duration) linear infinite;
                    animation: box2 var(--duration) linear infinite;
          }
          .boxes .box:nth-child(3) {
            transform: translate(100%, 100%);
            -webkit-animation: box3 var(--duration) linear infinite;
                    animation: box3 var(--duration) linear infinite;
          }
          .boxes .box:nth-child(4) {
            transform: translate(200%, 0);
            -webkit-animation: box4 var(--duration) linear infinite;
                    animation: box4 var(--duration) linear infinite;
          }
          .boxes .box > div {
            --background: #5C8DF6;
            --top: auto;
            --right: auto;
            --bottom: auto;
            --left: auto;
            --translateZ: calc(var(--size) / 2);
            --rotateY: 0deg;
            --rotateX: 0deg;
            position: absolute;
            width: 100%;
            height: 100%;
            background: var(--background);
            top: var(--top);
            right: var(--right);
            bottom: var(--bottom);
            left: var(--left);
            transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));
          }
          .boxes .box > div:nth-child(1) {
            --top: 0;
            --left: 0;
          }
          .boxes .box > div:nth-child(2) {
            --background: #145af2;
            --right: 0;
            --rotateY: 90deg;
          }
          .boxes .box > div:nth-child(3) {
            --background: #447cf5;
            --rotateX: -90deg;
          }
          .boxes .box > div:nth-child(4) {
            --background: #DBE3F4;
            --top: 0;
            --left: 0;
            --translateZ: calc(var(--size) * 3 * -1);
          }
          
          @-webkit-keyframes box1 {
            0%, 50% {
              transform: translate(100%, 0);
            }
            100% {
              transform: translate(200%, 0);
            }
          }
          
          @keyframes box1 {
            0%, 50% {
              transform: translate(100%, 0);
            }
            100% {
              transform: translate(200%, 0);
            }
          }
          @-webkit-keyframes box2 {
            0% {
              transform: translate(0, 100%);
            }
            50% {
              transform: translate(0, 0);
            }
            100% {
              transform: translate(100%, 0);
            }
          }
          @keyframes box2 {
            0% {
              transform: translate(0, 100%);
            }
            50% {
              transform: translate(0, 0);
            }
            100% {
              transform: translate(100%, 0);
            }
          }
          @-webkit-keyframes box3 {
            0%, 50% {
              transform: translate(100%, 100%);
            }
            100% {
              transform: translate(0, 100%);
            }
          }
          @keyframes box3 {
            0%, 50% {
              transform: translate(100%, 100%);
            }
            100% {
              transform: translate(0, 100%);
            }
          }
          @-webkit-keyframes box4 {
            0% {
              transform: translate(200%, 0);
            }
            50% {
              transform: translate(200%, 100%);
            }
            100% {
              transform: translate(100%, 100%);
            }
          }
          @keyframes box4 {
            0% {
              transform: translate(200%, 0);
            }
            50% {
              transform: translate(200%, 100%);
            }
            100% {
              transform: translate(100%, 100%);
            }
          }
          html {
            -webkit-font-smoothing: antialiased;
          }
        </style>
    `;
    return style;
};

const getDesktopLoaderWrapperCss = () => `
    position:fixed;
    height:520px;
    width:320px;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    background-color:white; 
    border-radius:10px;
    z-index:2147483647;
    padding-top:150px;
    max-width: 100vw;
    `;

const getMobileLoaderWrapperCss = () => `
    position:fixed; 
    bottom: 0;
    width:100vw;
    height:40vh;
    max-height: 200px;
    background-color:#fff;
    border-radius:25px;
    border-bottom-right-radius:0;
    border-bottom-left-radius:0;
    color:rgba(0,0,0,0.8);
    box-sizing:border-box;
    z-index:2147483647;`;

const showLoader = (paymentWindow, abortPaymentCallback) => {
    const template = `
        ${getLoaderStyle()}
        <div 
            id="cf-loader"
            style="position:fixed;top:0;left:0;height:100vh;width:100vw;background-color:rgba(0,0,0,0.5);z-index:2147483647;"
        >
            <div 
                style="${isMobile() ? getMobileLoaderWrapperCss() : getDesktopLoaderWrapperCss()}"
            >
                <p style="text-align:center;padding-top:1rem;font-size:16px;color:#113A5B">Your payment is being processed.</p>
                <div style="position:relative;text-align: center;">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%; display: block; shape-rendering: auto;" width="81px" height="81px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                  <circle cx="84" cy="50" r="10" fill="#ffb6bb">
                      <animate attributeName="r" repeatCount="indefinite" dur="0.6756756756756757s" calcMode="spline" keyTimes="0;1" values="8;0" keySplines="0 0.5 0.5 1" begin="0s"/>
                      <animate attributeName="fill" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="discrete" keyTimes="0;0.25;0.5;0.75;1" values="#ffb6bb;#585872;#95d5ee;#ffe691;#ffb6bb" begin="0s"/>
                  </circle><circle cx="16" cy="50" r="10" fill="#ffb6bb">
                    <animate attributeName="r" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;8;8;8" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"/>
                    <animate attributeName="cx" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"/>
                  </circle><circle cx="50" cy="50" r="10" fill="#ffe691">
                    <animate attributeName="r" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;8;8;8" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.6756756756756757s"/>
                    <animate attributeName="cx" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.6756756756756757s"/>
                  </circle><circle cx="84" cy="50" r="10" fill="#95d5ee">
                    <animate attributeName="r" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;8;8;8" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.3513513513513513s"/>
                    <animate attributeName="cx" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.3513513513513513s"/>
                  </circle><circle cx="16" cy="50" r="10" fill="#585872">
                    <animate attributeName="r" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;8;8;8" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-2.027027027027027s"/>
                    <animate attributeName="cx" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-2.027027027027027s"/>
                  </circle>
                </svg>
                <p style="text-align:center;font-size:16px;border-bottom:1px solid #113A5B; display: inline-block">
                    <button 
                        id="${paymentWindow ? 'cf-btn-loader-goto' : 'cf-btn-loader-cancel'}"
                        style="background:none;font-family:inherit;border:none;font-size:16px;cursor:pointer;"    
                    >
                        ${paymentWindow ? 'Go to Payment.' : 'Cancel Payment'}
                    </button>
                </p>
                </div>
            </div>
        </div>
    `;
    const loaderWrapper = document.createElement('div');
    loaderWrapper.innerHTML = template;
    document.body.appendChild(loaderWrapper);
    disablePageScroll(document.getElementById('cf-loader'));
    if (document.getElementById('cf-btn-loader-goto') !== null) {
        document.getElementById('cf-btn-loader-goto').addEventListener('click', () => paymentWindow.focus());
    }
    if (document.getElementById('cf-btn-loader-cancel') !== null) {
        document.getElementById('cf-btn-loader-cancel').addEventListener('click', () => abortPaymentCallback());
    }
};

const hideLoader = () => {
    enablePageScroll(document.getElementById('cf-loader'));
    if (document.getElementById('cf-loader')) {
        document.getElementById('cf-loader').remove();
    }
};

export { showLoader, hideLoader };
