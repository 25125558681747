import { UPI_QRCODE } from '../../shared/constants/payment-channels';
import { openIframeWithSource } from '../../shared/iframe-helper';
import { Order } from '../Order';

export default class QrCode {
    pay = async (paymentSessionID, pluginName, locationParent) => {
        const paymentMethod = {
            upi: {
                channel: UPI_QRCODE,
            },
        };
        const orderPayResponse = await Order.intentPay(paymentSessionID, paymentMethod, 'upi-qrcode', pluginName, locationParent);
        this._statusToken = orderPayResponse.statusToken;
        
        return orderPayResponse.response;
    };

    _getQrCodeWrapperTemplate = qrcodeElement => {
        const template = `
            <div 
                style="position: fixed; top:50%; left:50%; transform:translate(-50%, -50%); height:100%; max-height: 420px; width: 100%;max-width: 420px; padding-left:0; padding-right:0; border:transparent; background-color:white; border-radius:10px;z-index:2147483647;"
            >
                <p 
                    style="font-size: 1rem; padding:15px; text-align:center; font-family:inherit;color: #11385B"
                >
                    Scan the QR Code using your UPI apps
                </p>
                <div style="text-align:center; margin-bottom: 1rem;">
                    <img src="https://payments.cashfree.com/order/icons/gpay.png" alt="gpay" style="height:20px;width:49px;" />
                    <img src="https://payments.cashfree.com/order/icons/wallets/paytm.png" alt="paytm" style="height:20px;" />
                    <img src="https://payments.cashfree.com/order/icons/wallets/phonepe.png" alt="phonepe" style="height:20px;" />
                    <img src="https://payments.cashfree.com/order/icons/upi/bhim_logo.png" alt="bhim" style="height:20px;" />
                    <img
                    src="https://payments.cashfree.com/order/icons/wallets/amazonpay.png"
                    alt="amazonpay"
                    height="20px" />
                </div>
                <div style="position:relative;">
                ${qrcodeElement}
                </div>
                    <button
                        id="cancel-qrcode" 
                        style="background:none;font-family:inherit;border:none;font-size:16px;cursor:pointer;font-size: 16px;margin-top:36px; border-bottom: 1px solid #113A5B; font-family:inherit; color: #11385B;"
                    >
                        Cancel Payment
                    </button>
            </div>`;

        return template;
    };

    _renderQrCode = encodedQrCode => {
        // prepare image
        const image = new Image();
        image.src = encodedQrCode;
        image.style.display = 'block';
        image.style.margin = 'auto';
        image.style.height = '50%';
        image.style.width = '50%';
        image.style.maxHeight = '320px';
        image.style.maxWidth = '320px';

        // prepare overlay
        const overlayDiv = document.createElement('div');
        overlayDiv.id = 'cashfree-qrcode-container';
        overlayDiv.style.position = 'fixed';
        overlayDiv.style.top = '0';
        overlayDiv.style.left = '0';
        overlayDiv.style.width = '100%';
        overlayDiv.style.height = '100%';
        overlayDiv.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        overlayDiv.style.zIndex = '2147483647';
        overlayDiv.style.textAlign =  'center';

        // append image to overlay div
        overlayDiv.innerHTML = this._getQrCodeWrapperTemplate(image.outerHTML);

        // inject overlay to DOM
        document.body.appendChild(overlayDiv);
    };

    handlePaymentResponse = (response, abortPaymentCallback) => {
        const { paymentWindow }  = window;
        if(paymentWindow && !paymentWindow.closed) {
            paymentWindow.close();
        }
        this._renderQrCode(response.data.payload.qrcode);
        document.getElementById('cancel-qrcode').addEventListener('click', () => {
            abortPaymentCallback();
            document.getElementById('cashfree-qrcode-container').remove();
        });
        openIframeWithSource('0', `${REDIRECT_HOST_URL}/status/qrcode/${this._statusToken}`);
    };
}
