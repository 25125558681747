export default {
    PAYMENT_CODE_LIMIT: {
        PAY_LATER: {
            4503: {
                MAX_AMOUNT: 10000,
            },
            4506: {
                MIN_AMOUNT: 100,
            },
        },
        CARDLESS_EMI: {
            6061: {
                MIN_AMOUNT: 1000,
            },
        },
    },
};
