import { SDKInitializationValidationErrors } from '../core/shared/constants/error-codes';
import packageInfo from '../../package.json';
import { initializeApp } from '../core/index';

export class Cashfree {
    constructor(paymentSessionId) {
        this.paymentSessionId = paymentSessionId;
        this.randomId = Math.floor(Math.random() * 100000);
    }

    serverURL = API_HOST_URL;
    
    // Commented code is for building Flutter UI SDK
    // getReplacer = () => {
    //     const seen = new WeakSet();
    //     return (key, value) => {
    //         if (typeof value === "object" && value !== null) {
    //             if (seen.has(value)) {
    //                 return {};
    //             }
    //             seen.add(value);
    //         }
    //         return value;
    //     };
    // };

    drop = async (container, config) => {
        if (!config) {
            throw new Error('[initialiseDropin] config missing');
        }
        if (!config.components) {
            throw new Error('[initialiseDropin] components missing in config.');
        }
        if (typeof config.onSuccess !== 'function') {
            throw new Error(SDKInitializationValidationErrors.PAYMENT_SUCCESS_CALLBACK_MISSING);
        }
        if (typeof config.onFailure !== 'function') {
            throw new Error(SDKInitializationValidationErrors.PAYMENT_FAILURE_CALLBACK_MISSING);
        }

        if (!this.paymentSessionId) {
            throw new Error('PaymentSessionId missing');
        }
        config.paymentSessionId = this.paymentSessionId;
        const successCallback = config.onSuccess;
        const failureCallback = config.onFailure;
        const self = this;

        window.addEventListener('message', msg => {
            const { orderData, randomId } = msg.data;
            if (randomId === 'Initial') {
                failureCallback(orderData);
                // failureCallback(JSON.stringify(orderData, this.getReplacer()));
            } else {
                if (self.randomId !== randomId) return;
                // if (orderData.order.status === 'PAID') successCallback(JSON.stringify(orderData, this.getReplacer()));
                // else failureCallback(JSON.stringify(orderData, this.getReplacer()));
                if (orderData.order.status === 'PAID') successCallback(orderData);
                else failureCallback(orderData);
            }
        });

        const oldFrame = document.getElementById('dropin_frame' + this.randomId);
        let ifrm;
        if (oldFrame) {
            ifrm = oldFrame;
        } else {
            ifrm = document.createElement('iframe');
        }

        ifrm.setAttribute('allowpaymentrequest', true);
        ifrm.id = 'dropin_frame' + this.randomId;
        ifrm.style = `
            width: 100%;
            height: 100%;
            border: none;
            background-color: rgb(248, 250, 247);
        `;
        delete config.onSuccess;
        delete config.onFailure;
        ifrm.onload = () => {
            const components = [...config.components];
            const orderIndex = components.indexOf('order-details');
            let frameHeight = 0;
            if (orderIndex > -1) {
                if (components.length === 1) {
                    ifrm.style.minHeight = '200px';
                } else {
                    frameHeight += 160;
                }
                components.splice(orderIndex, 1);
            }
            if (components.length === 1) {
                switch (components[0]) {
                    case 'card':
                        ifrm.style.minHeight = frameHeight + 200 + 'px';
                        break;
                    case 'upi-qrcode':
                        ifrm.style.minHeight = '360px';
                        break;
                    case 'upi-collect':
                        ifrm.style.minHeight = frameHeight + 190 + 'px';
                        break;
                    case 'app':
                        ifrm.style.minHeight = (frameHeight > 0 ? frameHeight - 30 : 0) + 475 + 'px';
                        break;
                    case 'netbanking':
                        ifrm.style.minHeight = frameHeight + 410 + 'px';
                        break;
                    case 'upi-intent':
                        ifrm.style.minHeight = frameHeight + 400 + 'px';
                        break;
                    default:
                        ifrm.style.minHeight = '100%';
                }
            } else if (components.length > 1) {
                if (components.includes('app')) {
                    ifrm.style.minHeight = (frameHeight > 0 ? frameHeight - 30 : 0) + 575 + 'px';
                } else if (components.includes('netbanking')) {
                    ifrm.style.minHeight = frameHeight + 470 + 'px';
                } else if (components.includes('upi-intent')) {
                    ifrm.style.minHeight = frameHeight + 400 + 'px';
                } else if (components.includes('card')) {
                    ifrm.style.minHeight = frameHeight + 340 + 'px';
                } else if (components.includes('qr-code')) {
                    ifrm.style.minHeight = frameHeight + 340 + 'px';
                } else if (components.includes('upi-collect')) {
                    ifrm.style.minHeight = frameHeight + 240 + 'px';
                }
            }
            config.frameHeight = ifrm.clientHeight;
            config.randomId = self.randomId;
            config.paymentSessionId = self.paymentSessionId;
            const { host, pathname, search, href } = window.location;
            config.parentUrl = host + pathname + search;
            config.locationParent = href;
            // ifrm.contentWindow.postMessage(JSON.parse(JSON.stringify(config, this.getReplacer())), '*'); 
            ifrm.contentWindow.postMessage(config, '*');
        };
        ifrm.src = `${REDIRECT_HOST_URL}/dropin/${packageInfo.version}`;
        container.appendChild(ifrm);
    };

    redirect = () => {
        const currentForm = document.getElementById('invisible-cf-div');
        if (currentForm) {
            currentForm.parentElement.removeChild(currentForm);
        }
        const div = document.createElement('div');
        div.setAttribute('id', 'invisible-cf-div');
        const url = this.serverURL + '/view/sessions/checkout';
        const form = document.createElement('form');
        form.setAttribute('id', 'invisible-cf-form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', url);
        form.setAttribute('target', '_top');
        form.style.width = '0px';
        form.style.height = '0px';
        form.style.display = 'none';

        if (!this.paymentSessionId) {
            throw new Error('Payment Session Id is required');
        }
        let FN = document.createElement('input');
        FN.setAttribute('type', 'text');
        FN.setAttribute('name', 'payment_session_id');
        FN.setAttribute('value', this.paymentSessionId);
        form.appendChild(FN);
        const meta = {
            userAgent: window.navigator.userAgent,
        };
        const sortedMeta = Object.entries(meta)
            .sort()
            .reduce((o, [k, v]) => {
                o[k] = v;
                return o;
            }, {});
        const base64Meta = btoa(JSON.stringify(sortedMeta));

        FN = document.createElement('input');
        FN.setAttribute('type', 'text');
        FN.setAttribute('name', 'browser_meta');
        FN.setAttribute('value', base64Meta);
        form.appendChild(FN);
        
        FN = document.createElement('input');
        FN.setAttribute('type', 'text');
        FN.setAttribute('name', 'platform');
        FN.setAttribute('value', 'js-ch');
        form.appendChild(FN);
        
        document.body.appendChild(div);
        div.appendChild(form);
        form.submit();
    };

    elements = elementConfig => {
        elementConfig.paymentSessionId = this.paymentSessionId;
        return initializeApp(elementConfig);
    };
}
