import { capitalizeFirstLetter } from './string-helper';

export class CashfreeException extends Error {
    constructor(message, type, paymentMode, apiCode) {
        super(capitalizeFirstLetter(message));
        super.type = type;
        super.paymentMode = paymentMode || '';
        super.apiCode = apiCode || '';
    }
}
