// payment_method [CARD]
const CARD_LINK = 'link';
const CARD_MOTO = 'moto';
const CARD_NATIVE = 'native';
const CARD_POST = 'post';

// payment_metod [UPI]
const UPI_COLLECT = 'collect';
const UPI_LINK = 'link';
const UPI_QRCODE = 'qrcode';

// payment_method [APP]
const APP_PHONEPE = 'phonepe';
const APP_PAYTM = 'paytm';
const APP_AMAZON = 'amazon';
const APP_FREECHARGE = 'freecharge';
const APP_MOBIKWIK = 'mobikwik';
const APP_JIO = 'jio';
const APP_OLA = 'ola';
const APP_GPAY = 'gpay';

// payment_method [NETBANING]
const NETBANKING_LINK = 'link';

export {
    CARD_LINK,
    CARD_MOTO,
    CARD_NATIVE,
    UPI_COLLECT,
    UPI_LINK,
    UPI_QRCODE,
    APP_PHONEPE,
    APP_PAYTM,
    APP_AMAZON,
    APP_FREECHARGE,
    APP_MOBIKWIK,
    APP_JIO,
    APP_OLA,
    APP_GPAY,
    NETBANKING_LINK,
    CARD_POST,
};
