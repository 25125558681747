export default class CashfreeDOM {
    constructor(payObj) {
        this._payObj = payObj;
    }

    on = (eventName, callback) => {
        switch (eventName) {
            case 'change':
                this._payObj._onChange = callback;
                break;
            default:
                console.error('unsupported event');
        }
    };
}
