import { NetBankingSelector } from '../../shared/constants/selectors';
import { Order } from '../Order';
import { checkElementsValidity, checkInputValidity, checkPaymentRequestValidity } from './NetBankingValidation';

export default class NetBanking {
    constructor(netBankingElement, onChange) {
        this._netBankingElement = netBankingElement;
        this._onChange = onChange;
        this._state = {
            bankCode: '',
            ifscCode: '',
            accountNumber: '',
        };
        checkElementsValidity(netBankingElement);
        this._setSelectors();
        this._syncState();
        this._registerListeners();
    }

    _syncState = () => {
        this._state.bankCode = this._bankCodeElement.value;
        if (this._ifscCodeElement) {
            this._state.ifscCode = this._ifscCodeElement.value;
        }
        if (this._accountNumberElement) {
            this._state.accountNumber = this._accountNumberElement.value;
        }
    };

    _setSelectors = () => {
        this._bankCodeElement = this._netBankingElement.querySelector(NetBankingSelector.BANK_CODE);
        this._ifscCodeElement = this._netBankingElement.querySelector(NetBankingSelector.BANK_IFSC_CODE);
        this._accountNumberElement = this._netBankingElement.querySelector(NetBankingSelector.BANK_ACCOUNT_NUMBER);
    };

    _registerListeners = () => {
        this._bankCodeElement.addEventListener('change', this._handleBankCodeChange.bind(this));
        this._bankCodeElement.addEventListener('keyup', this._handleBankCodeChange.bind(this));

        if (this._ifscCodeElement) {
            this._ifscCodeElement.addEventListener('change', this._handleIfscCodeChange);
            this._ifscCodeElement.addEventListener('keyup', this._handleIfscCodeChange);
        }

        if (this._accountNumberElement) {
            this._accountNumberElement.addEventListener('change', this._handleAccountNumberChange);
            this._accountNumberElement.addEventListener('keyup', this._handleAccountNumberChange);
        }
    };

    _handleBankCodeChange = () => {
        this._state.bankCode = this._bankCodeElement.value;
        if (this._onChange) {
            this._onChange(checkInputValidity(this._state, this._netBankingElement));
        }
    };

    _handleIfscCodeChange = () => {
        this._state.ifscCode = this._ifscCodeElement.value;
        if (this._onChange) {
            this._onChange(checkInputValidity(this._state, this._netBankingElement));
        }
    };

    _handleAccountNumberChange = () => {
        this._state.accountNumber = this._accountNumberElement.value;
        if (this._onChange) {
            this._onChange(checkInputValidity(this._state, this._netBankingElement));
        }
    };

    isValid = () => {
        if(!this._state.bankCode) {
            return false;
        }
        return true;
    }

    pay = async (paymentSessionID, pluginName, locationParent) => {
        this._syncState();
        checkPaymentRequestValidity(this._state, this._netBankingElement);
        const paymentMethod = {
            netbanking: {
                channel: 'link',
                netbanking_bank_code: parseInt(this._state.bankCode, 10),
                netbanking_ifsc: this._state.ifscCode,
                netbanking_account_number: this._state.accountNumber,
            },
        };

        const orderPayResponse = await Order.pay(paymentSessionID, paymentMethod, 'netbanking', pluginName, locationParent);
        this._statusToken = orderPayResponse.statusToken;

        return orderPayResponse.response;
    };

    handlePaymentResponse = (response, abortPaymentCallback, paymentData) => {
        paymentData({...this._state, paymentMode: 'netbanking'});
        return Order.handlePaymentResponse(response, 'netbanking', null, abortPaymentCallback);
    }
}
