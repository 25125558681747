import { generalError } from '../../shared/constants/error-types';
import { UPI_LINK } from '../../shared/constants/payment-channels';
import { UPISelector } from '../../shared/constants/selectors';
import { CashfreeException } from '../../shared/exception-helper';
import { closeIframe, openIframeWithSource } from '../../shared/iframe-helper';
import { showLoader } from '../../shared/loader';
import { Order } from '../Order';
import IntentModal  from './IntentModal';
import {
    isIntentAvailable,
    isPhonePeIntentAvailable,
} from './PaymentRequestExtension';
import {
    checkIntentElementsValidity,
    checkIntentInputValidity,
    checkIntentPaymentRequestValidity,
    isValidUpiProvider,
} from './UPIValidation';
import analytics from "../../analytics";
import { detectBrowser } from '../../shared/device-helper';

export default class Intent {
    constructor(intentElement, onChange) {
        this._intentElement = intentElement;
        this._onChange = onChange;
        this._state = {
            upiProvider: '',
            isPhonepeIntentSupported: false,
            isGpayIntentSupported: false,
        };
        this._setSelectors();
        checkIntentElementsValidity(intentElement);
        this._syncState();
        this._registerListeners();
        analytics.track("open_intent");
    }

    _syncState = () => {
        this._state.upiProvider = this._upiProviderElement.value;
    };

    _checkIntentSupport = async () => {
        try {
            this._state.isGpayIntentSupported = await isIntentAvailable("https://tez.google.com/pay");;
        } catch (err) {
            console.log(err);
        }
        try {
            this._state.isPhonepeIntentSupported = await isPhonePeIntentAvailable();;
        } catch (err) {
            console.log(err);
        }
    };

    _setSelectors = () => {
        this._upiProviderElement = this._intentElement.querySelector(UPISelector.INTENT_PROVIDER);
    };

    _registerListeners = () => {
        this._upiProviderElement.addEventListener('change', this._handleUpiProviderChange);
    };

    _handleUpiProviderChange = () => {
        this._state.upiProvider = this._upiProviderElement.value;
        if (this._onChange) {
            this._onChange(checkIntentInputValidity(this._state));
        }
    };

    isValid = () => {
        if(!this._state.upiProvider) {
            return false;
        }
        return true;
    }

    pay = async (paymentSessionID, pluginName, locationParent) => {
        this._syncState();
        checkIntentPaymentRequestValidity(this._state);

        const paymentMethod = {
            upi: {
                channel: UPI_LINK,
                phone: this._state.phoneNumber,
            },
        };

        const orderPayResponse = await Order.intentPay(paymentSessionID, paymentMethod, 'upi-intent', pluginName, locationParent);
        this._statusToken = orderPayResponse.statusToken; // token to call status endpoint

        return orderPayResponse.response;
    };

    iosAppOpened = () => {
        showLoader(null, this.abortCallback);
        openIframeWithSource('0', `${REDIRECT_HOST_URL}/status/intent/${this._statusToken}`);
    }

    openLink = (url) => {
        let intentUrl = url;
        const userAgent = navigator.userAgent.toLowerCase();
        const androidUser = userAgent.indexOf("android") > -1;
        const iosUser = navigator.userAgent.indexOf("Mac") > -1 || /iPad|iPhone|iPod/.test(navigator.userAgent);
        const splittedURL = intentUrl.split("/pay");
		const upiPayString = splittedURL[1];
        const appIds = {
            gpay: {
                "id": "com.google.android.apps.nbu.paisa.user",
            },
            phonepe: {
                "id": "com.phonepe.app",
            },
            paytm: {
                "id": "net.one97.paytm",
            },
            bhim: {
                "id": "in.org.npci.upiapp",
            },
            others: {
                "id": "in.org.npci.upiapp",
            }
        };

        if(androidUser) {
            if (this._state.upiProvider === "phonepe") {
                intentUrl = "phonepe://pay" + upiPayString;
            } else {
                intentUrl = "intent://pay" + upiPayString;
            }
            intentUrl = intentUrl + "#Intent;scheme=upi;package=" + appIds[this._state.upiProvider].id + ";end";
            if(this._state.upiProvider === "others") {
                intentUrl = "upi://pay" + upiPayString;
            }
        } else if(iosUser) {
            if (this._state.upiProvider === "phonepe") {
                intentUrl = "phonepe://pay" + upiPayString;
            } else if (this._state.upiProvider === 'gpay') {
                intentUrl = "tez://upi/pay" + upiPayString;
            }
        };
        if(url.includes('payments-test.cashfree.com')) {
            intentUrl = url;
        }
        if(iosUser) {
            const browserType = detectBrowser();
            if(browserType === "AppleSafari"){
                const intentModal = new IntentModal();
                intentModal.renderModal(url, this._state.upiProvider, this.iosAppOpened, this.abortCallback);
            }else{
                let a = document.getElementById('iosupi');
                if (a == null) {
                    a = document.createElement('a');
                    a.id = 'iosupi';
                }
                a.href = url;
                a.onclick = this.iosAppOpened;
                document.body.appendChild(a);
                document.getElementById('iosupi').click();
            }
            return "modal";
        } 
        if (androidUser) {
            const a = document.createElement('a');
            const link = document.createTextNode("");
            a.appendChild(link);
            a.title = "";
            a.href = intentUrl;
            a.target = "_blank";
            a.click();
            return "href";
        } 
        return "none";
    }

    // The PaymentRequest failure flow will be taken care by try/catch block of the caller method.
    handlePaymentResponse = async (response, abortPaymentCallback, abortTimeout, paymentData) => {
        paymentData({...this._state, paymentMode: 'upi-intent'});
        // const handleTimeoutCallback = (msg) => {
        //     const { paymentWindow }  = window;
        //     if(paymentWindow && !paymentWindow.closed) {
        //         paymentWindow.close();
        //      }
        //     abortTimeout(msg);
        // }
        this.abortCallback = abortPaymentCallback;
        const { paymentWindow }  = window;
        try {
            // if (this._state.upiProvider === 'gpay' && this._state.isGpayIntentSupported && iosUser) {
            //     showLoader(null, abortPaymentCallback);
            //     openIframeWithSource('0', `${REDIRECT_HOST_URL}/status/intent/${this._statusToken}`);
            //     const paymentRequestParams = createPaymentRequestParams(response.data.payload.gpay);
            //     await initiatePaymentIntent(gpayBase, paymentRequestParams, this._state.orderToken, handleTimeoutCallback);
            // } else if (this._state.upiProvider === 'phonepe' && this._state.isPhonepeIntentSupported && iosUser) {
            //     showLoader(null, abortPaymentCallback);
            //     openIframeWithSource('0', `${REDIRECT_HOST_URL}/status/intent/${this._statusToken}`);
            //     const paymentRequestParams = createPaymentRequestParams(response.data.payload.phonepe);
            //     await initiatePaymentIntentByURL(phonepeBase, paymentRequestParams, response.data.payload.phonepe, handleTimeoutCallback);
            // } else 
            if (isValidUpiProvider(this._state.upiProvider)) {
                let linkUrl = "";
                if(response.data.payload[this._state.upiProvider]) {
                    linkUrl = response.data.payload[this._state.upiProvider];
                } else {
                    linkUrl = response.data.payload.default;
                }
                const linkOpen = this.openLink(linkUrl, abortPaymentCallback);
                if(linkOpen === "href") {
                    showLoader(null, abortPaymentCallback);
                    openIframeWithSource('0', `${REDIRECT_HOST_URL}/status/intent/${this._statusToken}`);
                }
            } else {
                console.warn('invalid upi provider, opening default one!');
                window.open(response.data.payload.default).focus();
            }
            return null;
        } catch (e) {
            if(paymentWindow && !paymentWindow.closed) {
               paymentWindow.close();
            }
            closeIframe();
            throw new CashfreeException(e.message, generalError);
        }
    };
}
