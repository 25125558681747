const initialiseValidationCallbackData = selectors => {
    const validityData = {
        isReadyToPay: false,
        errors: [],
    };

    selectors.forEach(selector =>
        validityData.errors.push({
            element: selector,
            error: false,
            message: '',
        })
    );

    return validityData;
};

const isReadyToPay = errorsList => {
    let isComplete = true;
    errorsList.forEach(err => {
        if (err.error === true) {
            isComplete = false;
        }
    });
    return isComplete; // no error
};

export { initialiseValidationCallbackData, isReadyToPay };
