import { CardValidationErrors, DOMErrors } from '../../shared/constants/error-codes';
import { domError, inputValidationError } from '../../shared/constants/error-types';
import { CardSelector, EMISelector } from '../../shared/constants/selectors';
import { CashfreeException } from '../../shared/exception-helper';
import {
    masterCardRegex,
    visaRegex,
    amexRegex,
    dinersRegex,
    discoverRegex,
    jcbRegex,
    maestroRegex,
    // cardHolderRegex,
    cardCvvRegex,
} from '../../shared/regex';
import { initialiseValidationCallbackData, isReadyToPay } from '../../shared/validation-helper';

// ###################### VALIDATE CARD DATA ######################## //

const isValidCardNumber = value => {
    // Accept only digits, dashes or spaces
    const dummyCards = ['4434260000000000', '4434260000000001'];
    if (/[^0-9-\s]+/.test(value)) return false;

    // The Luhn Algorithm. It's so pretty.
    let nCheck = 0;
    let bEven = false;
    // eslint-disable-next-line no-param-reassign
    value = value.replace(/\D/g, '');

    // eslint-disable-next-line no-plusplus
    for (let n = value.length - 1; n >= 0; n--) {
        const cDigit = value.charAt(n);
        let nDigit = parseInt(cDigit, 10);

        // eslint-disable-next-line no-cond-assign
        if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

        nCheck += nDigit;
        bEven = !bEven;
    }

    return nCheck % 10 === 0 || dummyCards.indexOf(value) > -1;
};

const getCardBrand = cardNumber => {
    let brand = '';
    const cardNumberWithoutSpaces = cardNumber.replace(/ +/g, '');

    if (masterCardRegex.test(cardNumberWithoutSpaces)) {
        brand = 'mastercard';
    }
    if (visaRegex.test(cardNumberWithoutSpaces)) {
        brand = 'visa';
    }
    if (amexRegex.test(cardNumberWithoutSpaces)) {
        brand = 'americanexpress';
    }
    if (dinersRegex.test(cardNumberWithoutSpaces)) {
        brand = 'diners';
    }
    if (discoverRegex.test(cardNumberWithoutSpaces)) {
        brand = 'discover';
    }
    if (jcbRegex.test(cardNumberWithoutSpaces)) {
        brand = 'jcb';
    }
    if (maestroRegex.test(cardNumberWithoutSpaces)) {
        brand = 'maestro';
    }

    return brand;
};

const isValidCardCvv = cardCvv => cardCvvRegex.test(cardCvv);

// const isValidExpiryMonth = month => Number(month) >= 1 && Number(month) <= 12;

const isValidExpiryYear = year => Number(year) >= new Date().getFullYear() % 100;

const isValidExpiry = (month, year) => {
    if (month && year) {
        if (Number(month) >= new Date().getMonth() + 1) {
            // month is valid
            return isValidExpiryYear(year);
        }
        return isValidExpiryYear(year - 1);
    }
    return false;
};

// ###################### VALIDATE CARD ELEMENTS ######################## //

const _isValidCardNumberElement = cardNumberElement => cardNumberElement != null;

const _isValidExpiryYearElement = expiryYearElement => expiryYearElement != null;

const _isValidExpiryMonthElement = expiryMonthElement => expiryMonthElement != null;

class CardValidation {
    constructor(mode) {
        this._selectorBase = mode === 'card' ? CardSelector : EMISelector;
        this._domErrorBase = mode === 'card' ? DOMErrors.Card : DOMErrors.Emi;
        this._paymentMode = mode;
    }

    checkElementsValidity(cardElementsWrapper) {
        if (!_isValidCardNumberElement(cardElementsWrapper.querySelector(this._selectorBase.CARD_NUMBER))) {
            throw new CashfreeException(this._domErrorBase.CARD_NUMBER_ELEMENT_NOT_FOUND, domError);
        }
        if (!_isValidExpiryMonthElement(cardElementsWrapper.querySelector(this._selectorBase.CARD_EXPIRY_MONTH))) {
            throw new CashfreeException(this._domErrorBase.CARD_EXPIRY_MONTH_ELEMENT_NOT_FOUND, domError);
        }
        if (!_isValidExpiryYearElement(cardElementsWrapper.querySelector(this._selectorBase.CARD_EXPIRY_YEAR))) {
            throw new CashfreeException(this._domErrorBase.CARD_EXPIRY_YEAR_ELEMENT_NOT_FOUND, domError);
        }
    }

    checkInputValidity(paymentRequest) {
        const selectors = [
            this._selectorBase.CARD_NUMBER,
            this._selectorBase.CARD_EXPIRY_YEAR,
            this._selectorBase.CARD_CVV,
            this._selectorBase.CARD_EXPIRY_MONTH,
            this._selectorBase.CARD_HOLDER,
        ];

        const validityData = initialiseValidationCallbackData(selectors);
        validityData.brand = getCardBrand(paymentRequest.cardNumber);

        if (!isValidCardNumber(paymentRequest.cardNumber)) {
            validityData.errors[0].error = true;
            validityData.errors[0].message = 'card : Invalid Card Number entered';
        }
        if (!isValidCardCvv(paymentRequest.cvv)) {
            validityData.errors[2].error = true;
            validityData.errors[2].message = 'card_cvv : Card Cvv must be 3 digits';
        }
        if (paymentRequest.cardExpiryMonth.length === 1) {
            validityData.errors[3].error = true;
            validityData.errors[3].message = 'card_expiry_mm : Expiry month should be double digit';
        } else if (!isValidExpiry(paymentRequest.cardExpiryMonth, paymentRequest.cardExpiryYear)) {
            validityData.errors[3].error = true;
            validityData.errors[3].message = 'Invalid card expiry date';
        }
        if (!isValidExpiry(paymentRequest.cardExpiryMonth, paymentRequest.cardExpiryYear)) {
            validityData.errors[1].error = true;
            validityData.errors[1].message = 'Invalid card expiry date';
        }

        validityData.isReadyToPay = isReadyToPay(validityData.errors);

        return validityData;
    }

    // eslint-disable-next-line class-methods-use-this
    checkPaymentRequestValidity(paymentRequest) {
        if (!isValidCardNumber(paymentRequest.cardNumber)) {
            throw new CashfreeException(
                CardValidationErrors.INVALID_CARD_NUMBER,
                inputValidationError,
                this._paymentMode
            );
        }
        if (!isValidCardCvv(paymentRequest.cvv)) {
            throw new CashfreeException(CardValidationErrors.INVALID_CARD_CVV, inputValidationError, this._paymentMode);
        }
        if (!isValidExpiry(paymentRequest.cardExpiryMonth, paymentRequest.cardExpiryYear)) {
            throw new CashfreeException(
                CardValidationErrors.INVALID_CARD_EXPIRY,
                inputValidationError,
                this._paymentMode
            );
        }
    }
}

export { CardValidation, getCardBrand };
