import { apiError } from './constants/error-types';
import { CashfreeException } from './exception-helper';
import { uuidv4 } from './string-helper';

const cacheObj = {};

const NetworkOnly = async (url, data = {}) => {
    const response = await fetch(url, data);
    if (response.status !== 200) {
        const failedRes = await response.json();
        throw new CashfreeException(failedRes.message, apiError, null, failedRes.code);
    }
    const responseJson = await response.json();
    return responseJson;
};

const CacheFirst = async (url, data) => {
    const cachedResponse = cacheObj[url];
    if (cachedResponse) return cachedResponse;
    let payload = data;
    const xRequestId = uuidv4();
    if (!payload) {
        payload = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-request-id': xRequestId,
            },
        };
    }
    const response = await fetch(url, payload);
    if (response.status !== 200) {
        const failedRes = await response.json();
        throw new CashfreeException(failedRes.message, apiError);
    }
    const responseJson = await response.json();
    cacheObj[url] = responseJson;
    return responseJson;
};

export { NetworkOnly, CacheFirst };
