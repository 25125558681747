const masterCardRegex = /^(5[1-5][0-9]{0,14}|2[2-7][0-9]{0,14})$/;
const visaRegex = /^4[0-9]{0,18}$/;
const amexRegex = /^3[47][0-9]{0,13}$/;
const dinersRegex = /^(36)[0-9]{0,12}$/;
const discoverRegex = /^(6011[0-9]{0,12}|(644|645|646|647|648|649)[0-9]{0,13}|65[0-9]{0,14})$/;
const jcbRegex = /^(352[8,9]{1}[0-9]{0,15}|35[4-8]{1}[0-9]{0,16})$/;
const maestroRegex = /^(5[0|6-8][0-9]{0,17}|6[0-9]{0,18})$/;

const cardHolderRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
const cardCvvRegex = /^[0-9]{3,4}$/;

const phoneNumberRegex = /^[6-9][0-9]{9}$/;

const ifscCodeRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
const accountNumberRegex = /^[a-z0-9]{9,25}$/i;

const upiIdRegex = /^[a-zA-Z0-9_\-.]+@[a-zA-Z]{3,}$/;

export {
    masterCardRegex,
    visaRegex,
    amexRegex,
    dinersRegex,
    discoverRegex,
    jcbRegex,
    maestroRegex,
    cardHolderRegex,
    cardCvvRegex,
    phoneNumberRegex,
    ifscCodeRegex,
    accountNumberRegex,
    upiIdRegex,
};
