import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import Card from './components/card/Card';
import App from './components/app/App';
import QrCode from './components/upi/QrCode';
import { SDKInitializationValidationErrors } from './shared/constants/error-codes';
import { closeIframe, listenToIframe } from './shared/iframe-helper';
import Intent from './components/upi/Intent';
import Collect from './components/upi/Collect';
import NetBanking from './components/netbanking/NetBanking';
import EMI from './components/emi/EMI';
import { generalError, redundantMethodCallError } from './shared/constants/error-types';
import { CashfreeException } from './shared/exception-helper';
import CashfreeDOM from './components/CashfreeDOM';
import { hideLoader } from './shared/loader';
import PaymentConfig from './components/PaymentConfig';
import Paylater from './components/paylater/Paylater';
import CardlessEMI from "./components/cardlessEmi/CardlessEMI";
import { phonepeAvailable, gpayAvailable } from './utility';
import packageInfo  from '../../package.json';
/**
 * @author Kumar Swapnil <kumar.swapnil@cashfree.com>
 * @summary JS library for building payment flows. It let's you either use your custom UI or leverage our prebuilt UI components, whichever suits you for building a flawless checkout experience for your users.
 */
class Cashfree {
    #config = {
        onPaymentSuccess: null,
        onPaymentFailure: null,
        onError: null,
    };

    #isOrderProcessing = false;

    constructor(config) {
        Sentry.init({
            dsn: 'https://cedc1464859a4af7a1d368f8aa398fef@o330525.ingest.sentry.io/6487617',
            integrations: [new BrowserTracing()],
            environment: ENVIRONMENT,
            allowUrls: ["cashfree.com"],
        });
        // validate required parameters -> onPaymentSuccess, onPaymentFailure, onError
        if (typeof config.onPaymentSuccess !== 'function') {
            throw new Error(SDKInitializationValidationErrors.PAYMENT_SUCCESS_CALLBACK_MISSING);
        }
        if (typeof config.onPaymentFailure !== 'function') {
            throw new Error(SDKInitializationValidationErrors.PAYMENT_FAILURE_CALLBACK_MISSING);
        }
        if (typeof config.onError !== 'function') {
            throw new Error(SDKInitializationValidationErrors.ERROR_CALLBACK_MISSING);
        }
        this.paymentSessionId = config.paymentSessionId;
        delete config.paymentSessionId;
        this.#config = Object.assign(this.#config, config);
        this.#isOrderProcessing = false;
        listenToIframe(this._handlePaymentStatusResponse);
    }

    /**
     *
     * @param {Object} elementInfo
     * @description Provides an interface to the merchant to add payment elements reflecting all the payment options that would be available to User.
     * @returns {CashfreeDOM} a Cashfree DOM wrapper to assist in validate-as-you-type.
     */
    // eslint-disable-next-line consistent-return
    element = elementInfo => {
        try {
            this._validateElementInput(elementInfo);

            switch (elementInfo.type.toLowerCase()) {
                case 'card':
                    this._card = new Card(elementInfo.pay, elementInfo.onChange);
                    return new CashfreeDOM(this._card);
                case 'netbanking':
                    this._netBanking = new NetBanking(elementInfo.pay, elementInfo.onChange);
                    return new CashfreeDOM(this._netBanking);
                case 'app':
                    this._app = new App(elementInfo.pay, elementInfo.onChange);
                    return new CashfreeDOM(this._app);
                case 'upi-qrcode':
                    this._qrCode = new QrCode();
                    break;
                case 'upi-collect':
                    this._collect = new Collect(elementInfo.pay, elementInfo.onChange);
                    return new CashfreeDOM(this._collect);
                case 'upi-intent':
                    this._intent = new Intent(elementInfo.pay, elementInfo.onChange);
                    this._intent._checkIntentSupport(); // check support for PaymentRequestAPI
                    return new CashfreeDOM(this._intent);
                case 'emi':
                    this._emi = new EMI(elementInfo.pay, elementInfo.onChange);
                    return new CashfreeDOM(this._emi);
                case 'paylater':
                    this._paylater = new Paylater(elementInfo.pay, elementInfo.onChange);
                    return new CashfreeDOM(this._paylater);
                case 'cardlessemi':
                    this._cardlessEmi = new CardlessEMI(elementInfo.pay, elementInfo.onChange);
                    return new CashfreeDOM(this._cardlessEmi);
                default:
                    this.#config.onError(`Element initialization failed for: ${JSON.stringify(elementInfo)}`);
            }
        } catch (e) {
            console.error(e);
            this.#config.onError({ message: e.message, type: e.type ? e.type : generalError });
        }
    };

    _validateElementInput = elementInfo => {
        if (!elementInfo) {
            throw new CashfreeException(`required parameters missing. Please check the docs.`, generalError);
        }
        if (!elementInfo.type) {
            throw new CashfreeException(`type argument missing. Please check the docs.`, generalError);
        }
        if (elementInfo.type === 'upi-qrcode') {
            return;
        }

        if (!elementInfo.pay) {
            throw new CashfreeException(`pay argument missing for: ${elementInfo.type}`, generalError);
        }
    };

    elements = elementsInfoList => {
        if (!Array.isArray(elementsInfoList)) {
            this.#config.onError({ message: `Expecting a list, got ${typeof elementsInfoList}`, type: generalError });
            return;
        }

        elementsInfoList.forEach(elementInfo => this.element(elementInfo));
    };

    pay = async (paymentMode, pluginName, locationParent, showBankPage = false) => {
        let apiResponse;
        const baseInitialisationError = ' element not initialised. are you missing element method?';
        if(!pluginName) {
            pluginName = `jsxx-e-${packageInfo.version}-x`;
        }

        try {
            if (this.#isOrderProcessing) {
                throw new CashfreeException(
                    `payment already in process for payment session id :: ${this.paymentSessionId}`,
                    redundantMethodCallError
                );
            }

            this.#isOrderProcessing = true; // establish lock for transaction
            switch (paymentMode) {
                case 'card':
                    if (!this._card) throw new CashfreeException(`card${baseInitialisationError}`, generalError);
                    apiResponse = await this._card.pay(this.paymentSessionId, pluginName, showBankPage, locationParent);
                    this._paymentWindow = this._card.handlePaymentResponse(apiResponse, this._abortPayment, this.payHeadless);
                    break;
                case 'app':
                    if (!this._app) throw new CashfreeException(`app${baseInitialisationError}`);
                    apiResponse = await this._app.pay(this.paymentSessionId, pluginName, locationParent);
                    this._paymentWindow = this._app.handlePaymentResponse(apiResponse, this._abortPayment, this.setPaymentData);
                    break;
                case 'upi-intent':
                    if (!this._intent) throw new CashfreeException(`upi-intent${baseInitialisationError}`);
                    apiResponse = await this._intent.pay(this.paymentSessionId, pluginName, locationParent);
                    this._paymentWindow = await this._intent.handlePaymentResponse(apiResponse, this._abortPayment, this._abortTimeout, this.setPaymentData);
                    break;
                case 'netbanking':
                    if (!this._netBanking) throw new CashfreeException(`netbanking${baseInitialisationError}`);
                    apiResponse = await this._netBanking.pay(this.paymentSessionId, pluginName, locationParent);
                    this._paymentWindow = this._netBanking.handlePaymentResponse(apiResponse, this._abortPayment, this.setPaymentData);
                    break;
                case 'upi-collect':
                    if (!this._collect) throw new CashfreeException(`upi-collect${baseInitialisationError}`);
                    apiResponse = await this._collect.pay(this.paymentSessionId, pluginName, locationParent);
                    this._paymentWindow = this._collect.handlePaymentResponse(apiResponse, this._abortPayment, this.setPaymentData);
                    break;
                case 'emi':
                    if (!this._emi) throw new CashfreeException(`emi${baseInitialisationError}`);
                    apiResponse = await this._emi.pay(this.paymentSessionId, pluginName, locationParent);
                    this._paymentWindow = this._emi.handlePaymentResponse(apiResponse, this._abortPayment);
                    break;
                case 'upi-qrcode':
                    if (!this._qrCode) throw new CashfreeException(`upi-qrcode${baseInitialisationError}`);
                    apiResponse = await this._qrCode.pay(this.paymentSessionId, pluginName, locationParent);
                    this._paymentWindow = this._qrCode.handlePaymentResponse(apiResponse, this._abortPayment);
                    break;
                case 'paylater':
                    if(!this._paylater)  throw new CashfreeException(`paylater${baseInitialisationError}`); 
                    apiResponse = await this._paylater.pay(this.paymentSessionId, pluginName, locationParent);
                    this._paymentWindow = this._paylater.handlePaymentResponse(apiResponse, this._abortPayment, this.setPaymentData, this.payHeadless);
                    break;
                case 'cardlessemi':
                    if(!this._cardlessEmi)  throw new CashfreeException(`cardlessEmi${baseInitialisationError}`); 
                    apiResponse = await this._cardlessEmi.pay(this.paymentSessionId, pluginName, locationParent);
                    this._paymentWindow = this._cardlessEmi.handlePaymentResponse(apiResponse, this._abortPayment);
                    break;
                default:
                    throw new CashfreeException(
                        `Supported payment Modes are card, netbanking, upi-qrcode, emi, upi-collect, upi-intent and app. You provided:: ${paymentMode}`,
                        generalError
                    );
            }
            this._handlePaymentWindowClose();
        } catch (e) {
            console.error(e);
            const { paymentWindow }  = window;
            if(paymentWindow && !paymentWindow.closed) {
                paymentWindow.close();
            }
            if (e.type !== redundantMethodCallError) {
                this.#isOrderProcessing = false;
            }
            this.#config.onError({ message: e.message, type: e.type, paymentMode: e.paymentMode });
        }
    };

    checkValidity = (paymentMode) => {
        switch(paymentMode) {
            case 'card':
                return this._card.isValid();
            case 'netbanking':
                return this._netBanking.isValid();
            case 'app':
                return this._app.isValid();
            case 'upi-collect':
                return this._collect.isValid();
            case 'upi-qrcode':
                return true;
            case 'upi-intent':
                return this._intent.isValid();
            case 'paylater':
                return this._paylater.isValid();
            case 'creditcardemi':
            case 'debitcardemi':
                return this._emi.isValid();
            case 'cardlessemi':
                return this._cardlessEmi.isValid();
            default: 
                return false;
        }
    }

    getSupportedElements = async () => {
        try {
            const resp = await PaymentConfig.getSupportedPaymentModes(this.paymentSessionId);
            return resp;
        } catch (e) {
            console.error(e);
            this.#config.onError({ message: e.message, type: e.type, paymentMode: e.paymentMode });
            return null;
        }
    };

    getSupportedPaymentOptions = async paymentMode => {
        try {
            const resp = await PaymentConfig.getSupportedPaymentOptions(this.paymentSessionId, paymentMode);
            return resp;
        } catch (e) {
            console.error(e);
            this.#config.onError({ message: e.message, type: e.type, paymentMode: e.paymentMode });
            return null;
        }
    };

    getOrderDetails = async () => {
        try {
            const resp = await PaymentConfig.getOrderDetails(this.paymentSessionId);
            return resp;
        } catch (e) {
            console.error(e);
            return {};
        }
    }

    getTheme = async () => {
        try {
            const resp = await PaymentConfig.getTheme(this.paymentSessionId);
            return resp;
        } catch (e) {
            console.error(e);
            return null;
        }   
    }

    // hook this to "message" event (postMessage)
    _handlePaymentStatusResponse = postMessageData => {
        if(!postMessageData.order) return;
        // close the payment tab
        if (this._paymentWindow) {
            if(this._paymentWindow.close) {
                this._paymentWindow.close();
            }
            clearInterval(this._paymentWindowInterval);
        }

        // close iframe
        if (document.getElementById('cashfree-iframe-container')) {
            closeIframe();
        }

        // hide loader
        hideLoader();

        if (document.getElementById('cashfree-qrcode-container')) {
            document.getElementById('cashfree-qrcode-container').remove();
        }

        if (postMessageData.transaction && postMessageData.transaction.txStatus && postMessageData.transaction.txStatus.toLowerCase() === 'success') {
            this.#config.onPaymentSuccess(postMessageData);
        } else if(postMessageData.order) {
            this.#config.onPaymentFailure(postMessageData);
        }

        this.#isOrderProcessing = false; // reset value as Order is at terminal state (either success or failure)
    };

    _handlePaymentWindowClose = () => {
        this._paymentWindowInterval = setInterval(() => {
            if (this._paymentWindow && this._paymentWindow.closed) {
                this.#isOrderProcessing = false;
                hideLoader();
                // close iframe
                if (document.getElementById('cashfree-iframe-container')) {
                    closeIframe();
                }
                clearInterval(this._paymentWindowInterval);
                this.#config.onError({ message: 'User closed the Payment Window.', type: generalError });
            }
        }, 500);
    };

    _abortPayment = (hideError) => {
        this.#isOrderProcessing = false;
        hideLoader();
        if(!hideError) {
            this.#config.onError({ message: 'Payment aborted by the User.', type: generalError });
        }
        // close iframe
        if (document.getElementById('cashfree-iframe-container')) {
            closeIframe();
        }
        clearInterval(this._paymentWindowInterval);
    };

    payHeadless = async (responseData) => {
        const {
            transaction
        } = responseData;
        const orderData = await this.getOrderDetails();
        const callbackData = {
            transaction,
            order: {
                status: "PAID",
                orderId: orderData.orderId,
            }
        }
        this._handlePaymentStatusResponse(callbackData);
    }
    
    _abortTimeout = (msg) => {
        this.#isOrderProcessing = false;
        hideLoader();
        this.#config.onError({ message: msg, type: generalError });
        // close iframe
        if (document.getElementById('cashfree-iframe-container')) {
            closeIframe();
        }
        clearInterval(this._paymentWindowInterval);
    }

    setPaymentData = data => {
        this.paymentData = data;
    }

    getPaymentData = () => this.paymentData;
}

export const initializeApp = config => new Cashfree(config);

export const isPhonePeAvailable = phonepeAvailable;

export const isGpayAvailable = gpayAvailable;