import { CardValidation } from './CardValidation';
import EmiValidation from '../emi/EMIValidation';
import { Order } from '../Order';

export default class CardBase {
    constructor(cardElement, onChange, mode) {
        this._cardElement = cardElement;
        this._onChange = onChange;
        this._state = {
            cardNumber: '',
            cvv: '',
            cardHolder: '',
            cardExpiryMonth: '',
            cardExpiryYear: '',
        };
        this.bankData = {};
        this.payData = {};
        this._validator = mode === 'card' ? new CardValidation('card') : new EmiValidation('emi');
    }

    _syncState() {
        this._state.cardNumber = this._cardNumberElement.value;
        this._state.cvv = this._cardCvvElement.value;
        this._state.cardHolder = this._cardHolderElement.value;
        this._state.cardExpiryMonth = this._cardExpiryMonthElement.value;
        this._state.cardExpiryYear = this._cardExpiryYearElement.value;
    }

    _registerListeners() {
        this._cardNumberElement.addEventListener('keyup', this._handleCardNumberChange);
        this._cardCvvElement.addEventListener('keyup', this._handleCardCvvChange);
        this._cardExpiryMonthElement.addEventListener('keyup', this._handleCardExpiryMonthChange);
        this._cardExpiryYearElement.addEventListener('keyup', this._handleCardExpiryYearChange);
        this._cardHolderElement.addEventListener('keyup', this._handleCardHolderChange);

        // If a user inputs a value using autofill, keyup won't be triggered
        this._cardNumberElement.addEventListener('change', this._handleCardNumberChange);
        this._cardCvvElement.addEventListener('change', this._handleCardCvvChange);
        this._cardExpiryMonthElement.addEventListener('change', this._handleCardExpiryMonthChange);
        this._cardExpiryYearElement.addEventListener('change', this._handleCardExpiryYearChange);
        this._cardHolderElement.addEventListener('change', this._handleCardHolderChange);
    }

    _handleCardNumberChange = () => {
        this._state.cardNumber = this._cardNumberElement.value.replace(/ +/g, '');
        if (this._onChange) {
            this._onChange(this._validator.checkInputValidity(this._state));
        }
    };

    _handleCardCvvChange = () => {
        this._state.cvv = this._cardCvvElement.value;
        if (this._onChange) {
            this._onChange(this._validator.checkInputValidity(this._state));
        }
    };

    _handleCardExpiryMonthChange = () => {
        this._state.cardExpiryMonth = this._cardExpiryMonthElement.value.replace(/\//g, '').replace(/ +/g, '');
        if (this._onChange) {
            this._onChange(this._validator.checkInputValidity(this._state));
        }
    };

    _handleCardExpiryYearChange = () => {
        this._state.cardExpiryYear = this._cardExpiryYearElement.value;
        if (this._onChange) {
            this._onChange(this._validator.checkInputValidity(this._state));
        }
    };

    _handleCardHolderChange = () => {
        this._state.cardHolder = this._cardHolderElement.value;
        if (this._onChange) {
            this._onChange(this._validator.checkInputValidity(this._state));
        }
    };

    handlePaymentResponse = (response, abortPaymentCallback, payHeadless) => {
        response.bankData = this.bankData;
        response.payData = this.payData;
        return Order.handlePaymentResponse(response, 'card', null, abortPaymentCallback, payHeadless )
    };
}
