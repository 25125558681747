import { domError, inputValidationError } from '../../shared/constants/error-types';
import { DOMErrors } from '../../shared/constants/error-codes';
import { CashfreeException } from '../../shared/exception-helper';
import { NetBankingSelector } from '../../shared/constants/selectors';
import { accountNumberRegex, ifscCodeRegex } from '../../shared/regex';
import { initialiseValidationCallbackData, isReadyToPay } from '../../shared/validation-helper';

const isValidBankCode = bankCode => {
    if (bankCode) return true;
    return false;
};

const isValidIfscCode = ifscCode => ifscCodeRegex.test(ifscCode);

const isValidAccountNumber = accountNumber => accountNumberRegex.test(accountNumber);

const checkInputValidityForOptionalFields = (paymentRequest, netBankingElementsWrapper, validityData) => {
    const augmentedValidityData = { ...validityData };
    if (netBankingElementsWrapper.querySelector(NetBankingSelector.BANK_IFSC_CODE) != null) {
        if (!isValidIfscCode(paymentRequest.ifscCode)) {
            const errObj = { element: NetBankingSelector.BANK_IFSC_CODE, error: true, message: 'invalid ifsc code' };
            augmentedValidityData.errors[1] = { ...augmentedValidityData.errors[1], ...errObj };
        } else {
            const errObj = { element: NetBankingSelector.BANK_IFSC_CODE, error: false, message: '' };
            augmentedValidityData.errors[1] = { ...augmentedValidityData.errors[1], ...errObj };
        }
    }
    if (netBankingElementsWrapper.querySelector(NetBankingSelector.BANK_ACCOUNT_NUMBER) != null) {
        if (!isValidAccountNumber(paymentRequest.accountNumber)) {
            const errObj = {
                element: NetBankingSelector.BANK_ACCOUNT_NUMBER,
                error: true,
                message: 'invalid account number',
            };
            augmentedValidityData.errors[2] = { ...augmentedValidityData.errors[1], ...errObj };
        } else {
            const errObj = {
                element: NetBankingSelector.BANK_ACCOUNT_NUMBER,
                error: false,
                message: '',
            };
            augmentedValidityData.errors[2] = { ...augmentedValidityData.errors[1], ...errObj };
        }
    }
    return augmentedValidityData;
};

const checkInputValidity = (paymentRequest, netBankingElementsWrapper) => {
    const selectors = [NetBankingSelector.BANK_CODE];
    let validityData = initialiseValidationCallbackData(selectors);

    if (!isValidBankCode(paymentRequest.bankCode)) {
        validityData.errors[0].error = true;
        validityData.errors[0].message = 'invalid bank code';
    }
    validityData = checkInputValidityForOptionalFields(paymentRequest, netBankingElementsWrapper, validityData);
    validityData.isReadyToPay = isReadyToPay(validityData.errors);

    return validityData;
};

const checkElementsValidity = netBankingElementsWrapper => {
    if (!netBankingElementsWrapper.querySelector(NetBankingSelector.BANK_CODE)) {
        throw new CashfreeException(DOMErrors.NETBANKING_BANK_CODE_ELEMENT_NOT_FOUND, domError);
    }
    // if (!netBankingElementsWrapper.querySelector(NetBankingSelector.BANK_IFSC_CODE)) {
    //     throw new CashfreeException(DOMErrors.NETBANKING_IFSC_CODE_ELEMENT_NOT_FOUND, domError);
    // }
    // if (!netBankingElementsWrapper.querySelector(NetBankingSelector.BANK_ACCOUNT_NUMBER)) {
    //     throw new CashfreeException(DOMErrors.NETBANKING_ACCOUNT_NUMBER_ELEMENT_NOT_FOUND, domError);
    // }
};

const checkPaymentRequestValidity = (paymentRequest, netBankingElementsWrapper) => {
    if (!isValidBankCode(paymentRequest.bankCode)) {
        throw new CashfreeException('invalid bank code', inputValidationError);
    }
    if (
        netBankingElementsWrapper.querySelector(NetBankingSelector.BANK_IFSC_CODE) != null &&
        !isValidIfscCode(paymentRequest.ifscCode)
    ) {
        throw new CashfreeException('invalid ifsc code', inputValidationError);
    }
    if (
        netBankingElementsWrapper.querySelector(NetBankingSelector.BANK_ACCOUNT_NUMBER) != null &&
        !isValidAccountNumber(paymentRequest.accountNumber)
    ) {
        throw new CashfreeException('invalid account number', inputValidationError);
    }
};

export { checkElementsValidity, checkInputValidity, checkPaymentRequestValidity };
